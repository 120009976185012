import {
	createRoutine
} from 'redux-saga-routines';

export const uploadFile = createRoutine('UPLOAD_FILE');
export const clearState = createRoutine('CLEAR_STATE');
const INITIAL_STATE = {
	uploaded: false,
	error: ''
};

const fileUpload = (state = INITIAL_STATE, {
	type,
	payload
}) => {
	
	switch (type) {
		case clearState.TRIGGER:
			return {
				...state,
				isUploading: false,
				uploaded: false,
				error: ''
			};
		case uploadFile.TRIGGER:
			return {
				...state,
				isUploading: true,
				uploaded: false,
				error: ''
			};
		case uploadFile.SUCCESS:
			return {
				...state,
				uploaded: true,
				isUploading: false
			};
		case uploadFile.FAILURE:
			{
				return {
					...state,
					uploaded: true,
					isUploading: false,
					error: payload
				};
			}
		default:
			return state
	}
};

export default fileUpload;