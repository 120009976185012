import {createRoutine} from 'redux-saga-routines';

export const login = createRoutine('LOGIN');
export const logout = createRoutine('LOGOUT');
export const switchEvent = createRoutine('SWITCH_EVENT');
export const SUBMIT_LOGIN_TROUBLE_REQUEST = createRoutine('SUBMIT_LOGIN_TROUBLE_REQUEST');
export const UPDATE_STATE = createRoutine('UPDATE_STATE');
export const ON_REFRESH_TRIGGER = createRoutine('ON_REFRESH_TRIGGER');

const INITIAL_STATE = {
	token: '', site: '', loading: false, error: false, msg: '',
	loginTroubleSuccess: false, submitLoggingTroubleLoading: false
};

const loginAdmin = (state = INITIAL_STATE, {type, payload}) => {
	switch (type) {
		case login.TRIGGER:
			localStorage.removeItem('programId');
			localStorage.removeItem('role');
			return {...state, loading: true, msg: ''};
		case login.SUCCESS:
			const { token = '', site = '' } = payload || {};
			return {
				...state, token, site, loading: false
			};
		case login.FAILURE:
			return {
				...state, msg: payload, error: true, loading: false
			};
		
		case SUBMIT_LOGIN_TROUBLE_REQUEST.TRIGGER:
			return {...state, loginTroubleSuccess: false, message: '', msg: '', submitLoggingTroubleLoading: true};
		case SUBMIT_LOGIN_TROUBLE_REQUEST.SUCCESS:
			const { success = false, message = ''} = payload || {};
			return {...state, loginTroubleSuccess: success, message};
		case SUBMIT_LOGIN_TROUBLE_REQUEST.FAILURE:
			return {...state, msg: payload};
		case SUBMIT_LOGIN_TROUBLE_REQUEST.FULFILL:
			return {...state, submitLoggingTroubleLoading: false};
		
		case UPDATE_STATE.TRIGGER:
			localStorage.removeItem('programId');
			localStorage.removeItem('role');
			return{...state, message: '', loginTroubleSuccess: false};
			
		case ON_REFRESH_TRIGGER.TRIGGER:{
			return {...state, msg: '', loading: false};
		}
		
		case logout.TRIGGER:
			localStorage.removeItem('access_token');
			localStorage.removeItem('email');
			localStorage.removeItem('firstName');
			localStorage.removeItem('programId');
			localStorage.removeItem('role');
			localStorage.removeItem('site');
			return INITIAL_STATE;
		case logout.SUCCESS:
			return INITIAL_STATE;
		
		case switchEvent.TRIGGER:
			return INITIAL_STATE;
		
		case switchEvent.SUCCESS:
			return INITIAL_STATE;
		
		default:
			return state
		
	}
};

export default loginAdmin;
