import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class DeleteModal extends Component {
	constructor(props){
		super(props);
		this.state = {}
	}
	
	
	
	render() {
		const { isOpen = false, handleClose, handleDelete} = this.props;
		return (
			<Dialog
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Delete Admin</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Do you want to delete this admin ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleDelete} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default DeleteModal;
