
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import './index.css';
import { CONSTANTS, winnerTypes, division, ageGroupPitchHitRun, ageGroupJrhd } from "../../constants";
import TextField from "@material-ui/core/TextField/TextField";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Select from "@material-ui/core/Select/Select";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import { validate } from '../../helpers/utils';
import * as _ from 'lodash';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        padding: theme.spacing.unit,
        alignContent: 'center'
    },
}))(MuiDialogActions);

class CustomDialog extends React.Component {
    constructor(props) {
        super(props);

        this.site = localStorage.getItem('site');
        this.isJrhd = _.isEqual(this.site, 'jrhd');
        this.types = (this.isJrhd && ["Home Run Champ"]) || winnerTypes;

        this.state = {
            open: false,
            labelWidth: 90,
            division: '',
            ageGroup: '',
            winnerTypes: (this.isJrhd && [{ homeRunChamp: 0 }]) || [],
            isJrhd: this.isJrhd
        };
    }

    componentWillReceiveProps(props) {
        this.types = (this.isJrhd && ["Home Run Champ"]) || winnerTypes;
        this.setState({
            winnerTypes: (this.isJrhd && [{ homeRunChamp: 0 }]) || []
        })
    }


    handleClose = () => {
        const { handleClick } = this.props;
        this.setState({ open: false, division: '', ageGroup: '', errors: {}, winnerTypes: [] });
        let { open } = this.state;
        handleClick(open)
    };

    SelectHandler = event => {
        const { name, value, type } = event.target;
        const winnerTypes = _.cloneDeep(this.state.winnerTypes) || [];
        switch (type) {
            case "checkbox":

                const selectedTypes = _.map(winnerTypes, winnerType => Object.keys(winnerType)[0]);
                if (_.includes(selectedTypes, name)) {
                    _.remove(winnerTypes, winnerType => _.includes(Object.keys(winnerType), name));
                } else {
                    winnerTypes.push({ [name]: 0 });
                }

                break;
            case "number":

                const selectedType = _.remove(winnerTypes, winnerType => _.includes(Object.keys(winnerType), name));
                selectedType[0][name] = value;
                winnerTypes.push(selectedType[0]);

                break;
            default:
                this.setState({ [event.target.name]: event.target.value });
        }

        this.setState({ winnerTypes }, () => {
            let errors = {};
            if (!_.isEmpty(this.state.errors))
                errors = validate(this.state);

            this.setState({ errors });
        });

    };

    isInputDisabled = (fieldName) => {
        const { winnerTypes } = this.state;
        const selectedTypes = _.map(winnerTypes, winnerType => Object.keys(winnerType)[0]);
        return !_.includes(selectedTypes, fieldName);
    }

    getWinnerScore = (fieldName) => {
        const { winnerTypes } = this.state;
        const selectedTypes = _.find(winnerTypes, winnerType => _.includes(Object.keys(winnerType), fieldName));
        return (selectedTypes && selectedTypes[fieldName]) || '';
    }


    WinnerScoreHandler = (event) => {
        this.setState({ score: event.target.value });
    };


    submitHandler = () => {
        const errors = validate(this.state);
        if (!_.isEmpty(errors)) {
            this.setState({ errors });
            return;
        }

        const { division, ageGroup, winnerTypes } = this.state;
        this.props.handleSubmit({ division, ageGroup, winnerType: winnerTypes });
        this.handleClose();
    };

    render() {
        const { open, player } = this.props;
        const {playerFirstName, playerLastName} = player || {};

        const { errors, isJrhd } = this.state;
        const types = (_.isEqual(localStorage.getItem('site'), 'jrhd') && ['Home Run Champ']) || winnerTypes;

        const divisionItems = division.map((item, index) => {
            return (<MenuItem
                children={item}
                value={item === 'None' ? '' : item}
                key={`division-${index}`}
            />
            )
        });

        const ageGroup = isJrhd ? ageGroupJrhd : ageGroupPitchHitRun;
        const ageGroupItems = ageGroup.map((item, index) => {
            return (<MenuItem
                children={item}
                value={item === 'None' ? '' : item}
                key={`ageGroup-${index}`}
            />
            )
        });

        return (
            <Dialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="dialog-container-height"
            >
                <DialogTitle id="customized-dialog-title" className="winner-submit-header" onClose={this.handleClose}>
                    {CONSTANTS.winnerModalHeader}
                    {isJrhd && <p className="dialogContent-p-style">
                        Please select the age group for your winner and enter their Home Run total, then click SUBMIT
                        </p>
                    }

                    {!isJrhd &&
                        <p className="dialogContent-p-style">
                        You are selecting <b>{playerFirstName} {playerLastName}</b> as the winner for this event. As a result they will
                        advance to sectionals. Please enter their score below and hit submit.
                        </p>
                    }
                    
                    {!isJrhd &&
                        <ol className="dialogContent-p-style" style={{ listStyle: 'number' }}>
                            <li>Select winner’s Division and Age Group using the drop-down boxes.</li>
                            <li>Select the appropriate “winner type” for the player. Note: this may be more than one box.</li>
                            <li>Enter the score for ONLY the respective “winner type(s)” that were selected and hit submit.</li>
                        </ol>
                    }
                    {!isJrhd &&
                        <p className="dialogContent-p-style" style={{ fontStyle: 'italic' }}>Please refer to p.6 and p.16 in the administrative handbook for assistance with determining winners.</p>
                    }
                </DialogTitle>
                <DialogContent >
                    <div className="dialogContent-div-style">
                        {!isJrhd && <FormControl variant="outlined" style={{ minWidth: 300, marginBottom: 8 }}>
                            {<InputLabel>
                                Division
                                </InputLabel>}
                            <Select
                                name="division"
                                required
                                value={this.state.division}
                                onChange={this.SelectHandler}
                                input={
                                    <OutlinedInput
                                        labelWidth={this.state.labelWidth}
                                    />
                                }
                            >
                                {divisionItems}
                            </Select>
                        </FormControl>
                        }
                        {!isJrhd && errors && errors.division && <FormHelperText className="winner-errors-style" error={true}> {errors.division} </FormHelperText>}
                        <FormControl variant="outlined" style={{ minWidth: 300 }}>
                            {<InputLabel>
                                Age Group
                                </InputLabel>}
                            <Select
                                name="ageGroup"
                                required
                                value={this.state.ageGroup}
                                onChange={this.SelectHandler}
                                input={
                                    <OutlinedInput
                                        labelWidth={this.state.labelWidth}
                                    />
                                }
                            >
                                {ageGroupItems}
                            </Select>
                        </FormControl>
                        {errors && errors.ageGroup && <FormHelperText className="winner-errors-style" error={true}> {errors.ageGroup} </FormHelperText>}

                        {!isJrhd && <label className="dialogContent-p-style label">Winner Type:</label>}
                        {!isJrhd && _.map(types, (type, index) => {
                            if (type === 'Home Run Champ') {
                                return;
                            }
                            return <label className="dialogContent-p-style" key={`label-${index}`} for={`index-${index}`}>
                                <input key={`label-${index}`} name={_.camelCase(type)} id={`index-${index}`} type="checkbox" value={type} onChange={this.SelectHandler} /> {type}
                            </label>
                        })}

                        {errors && errors.winnerTypes &&
                            <FormHelperText className="winner-errors-style" error={true} style={{ paddingTop: '4px' }}>
                                {errors.winnerTypes}
                            </FormHelperText>}

                        {_.map(types, (type, index) => {
                            if (!isJrhd && type === 'Home Run Champ') {
                                return;
                            }
                            return <React.Fragment>
                                <TextField
                                    disabled={this.isInputDisabled(_.camelCase(type))}
                                    name={_.camelCase(type)}
                                    key={`txt-${index}`}
                                    autoComplete="false"
                                    id="outlined-required"
                                    label={_.isEqual(type, 'Home Run Champ') ? `Number of Home Runs` : `${type} Score`}
                                    defaultValue=""
                                    value={this.getWinnerScore(_.camelCase(type))}
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    className="dialog-text-field-width"
                                    onChange={this.SelectHandler}
                                    inputProps={{ min: 0, autoComplete: false }}
                                />
                                {errors && errors[_.camelCase(type)] &&
                                    <FormHelperText className="winner-errors-style" error={true} style={{ paddingTop: '4px' }}>
                                        {errors[_.camelCase(type)]}
                                    </FormHelperText>}
                            </React.Fragment>
                        })}

                    </div>
                </DialogContent>
                <DialogActions className="dialogAction-style">
                    <Button onClick={this.submitHandler} className="dialogAction-btn-style">
                        Submit
                            </Button>
                    <Button onClick={this.handleClose} className="dialogAction-btn-style">
                        Cancel
                            </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default CustomDialog;
