import {createRoutine} from 'redux-saga-routines';
import {ON_REFRESH_TRIGGER} from "./user";

export const appStartup = createRoutine('APP_STARTUP');

const INITIAL_STATE = {
	token: ''
};

const appStartUp = (state = INITIAL_STATE, {type, payload}) => {
	const {
		token = '',
	} = payload || '';
	
	switch (type) {
		case appStartup.TRIGGER:
			return {...state};
		case appStartup.SUCCESS:
			return {
				...state, token
			};
		case appStartup.FAILURE:
			return {
				...state
			};
		
		default:
			return state
	}
};

export default appStartUp;
