import ContentLoader from 'react-content-loader'
import React from 'react'

const PlayersLoader = props => (
    <ContentLoader
        height={830}
        width={1650}
        speed={2}
        primaryColor="#c0c0c0"
        secondaryColor="#ebebeb"
        {...props}
    >
        <rect x="20" y="20" rx="0" ry="0" width="380" height="50" />
	      <rect x="1530" y="40" rx="0" ry="0" width="90" height="40" />
	      <rect x="600" y="120" rx="0" ry="0" width="380" height="40" />
        <rect x="20" y="190" rx="0" ry="0" width="150" height="40" />
        <rect x="240" y="195" rx="0" ry="0" width="150" height="30" />
        <circle cx="220" cy="210" r="15" />
        <rect x="1530" y="190" rx="0" ry="0" width="90" height="40" />
        <rect x="1430" y="190" rx="0" ry="0" width="90" height="40" />
        <rect x="20" y="260" rx="0" ry="0" width="1600" height="500" />
        <rect x="20" y="770" rx="0" ry="0" width="1600" height="80" />

    </ContentLoader>
);

export default PlayersLoader
