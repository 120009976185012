import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {CONSTANTS, TROUBLE_LOGGING_FIELDS} from '../../constants';
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import CircularProgress from '@material-ui/core/CircularProgress';
import {isEqual, isEmpty} from 'lodash';
import {SUBMIT_LOGIN_TROUBLE_REQUEST} from "../../reducers/user";
import {checkError} from "../../helpers/utils";


class TroubleLoginFormModal extends React.Component {
	reg = new RegExp('^[^@]+@[^@]+\\.[^@]+$');
	constructor(props) {
		super(props);
		
		const {location = {}} = this.props;
		const {pathname = ''} = location || {};
		let uri = pathname.split('/')[1] || '';
		
		this.state = {
			eventName: '',
			firstName: '',
			email: '',
			eventId: '',
			comments: '',
			message: '',
			error: false,
			uri
		}
	}
	
	componentWillReceiveProps(nextProps){
		const { message = ''} = nextProps;
		const { message: prevMsg = ''} = this.props;
		if(!isEqual(message, prevMsg)){
			this.setState({message})
		}
	}
	
	onUpdateState = (event, id) => {
		const { target: {value = ''} = {}} = event || {};
		this.setState({...this.state, [id]: value})
	};
	
	onHandleModalClose = () => {
		const {updateState, handleUpdateModalOpen } = this.props;
		handleUpdateModalOpen(false);
		updateState();
		this.setState({error: false, email: '', eventName: '', firstName: '', eventId: '', comments: ''})
	};
	
	_renderMessage = () => {
		const { loginTroubleSuccess = false, submitLoggingTroubleLoading} = this.props;
		const { message = ''} = this.state;
		if(loginTroubleSuccess && !submitLoggingTroubleLoading){
			return (
				<span className='mt-3' style={{color: 'green'}}> {message} </span>
			)
		}
		return <span className='mt-3' style={{color: 'red'}}> {message} </span>;
	};
	
	
	onSubmitHandler = () => {
		const {submitLoggingTrouble} = this.props;
		const { email, firstName, eventName, comments, eventId, uri} = this.state;
		if(isEmpty(email) || isEmpty(firstName) || !this.reg.test(email)){
			this.setState({error: true});
			return null;
		}
		submitLoggingTrouble({email, firstName, eventName, comments, eventId, site: uri});
		this.setState({email: '', eventName: '', firstName: '', eventId: '', comments: '', error: false})
	};
	
	_renderWelcomeText = () => {
		let { uri } = this.state;
		if (isEqual(uri, CONSTANTS.site_1)) {
			uri = 'Pitch, Hit & Run'
		}
		return (
			<DialogContentText>
				{`Please fill out the below form and a representative will contact you within 24 hours.`}
			</DialogContentText>
		)
	};
	
	_renderTroubleLoginFields = () => {
		const { email, firstName, error} = this.state;
		let fields = {firstName, email};
		return TROUBLE_LOGGING_FIELDS.map((item = {}, index) => {
			const {autoFocus = false, id = '', type = 'text', label = '', multiline = false, rows = 0} = item || {};
			return (
				<TextField
					key={index}
					autoFocus={autoFocus}
					multiline={multiline}
					value={this.state[id]}
					error={checkError(id,fields, error)}
					rows={rows}
					margin="dense"
					variant="outlined"
					id={id}
					label={label}
					type={type}
					onChange={(event) => this.onUpdateState(event, id)}
					fullWidth
				/>
			)
		})
	};
	
	
	render() {
		const {isOpen = false, submitLoggingTroubleLoading = false} = this.props;
		return (
			<Dialog
				open={isOpen}
				onClose={this.onHandleModalClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogContent>
					{this._renderWelcomeText()}
					<br/>
					{this._renderTroubleLoginFields()}
					<br/>
					{this._renderMessage()}
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onHandleModalClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={this.onSubmitHandler} color="primary">
						{submitLoggingTroubleLoading && <CircularProgress color='primary' size={24}/>}
						{!submitLoggingTroubleLoading && <span>Submit</span>}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


const mapStateToProps = (
	{user: {message = '', loginTroubleSuccess = false, submitLoggingTroubleLoading = false} = {}}
) => (
	{message, loginTroubleSuccess, submitLoggingTroubleLoading}
);

const mapDispatchToProps = (dispatch) => (
	{
		submitLoggingTrouble: (data = {}) => dispatch(SUBMIT_LOGIN_TROUBLE_REQUEST(data))
	}
);


export default connect(mapStateToProps, mapDispatchToProps)(TroubleLoginFormModal);
