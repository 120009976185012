import React from 'react'
import './index.css'


class Footer extends React.Component{
	render() {
		return(
			<div className="footer-extra">
				<div className="footer-container">
							<div className="footer-title-style">
								<h4> Winner Submission Portal </h4>
							</div>
							<div className="footer-des-style">
								<p style={{margin: '0px 5px 5px 5px', textAlign: 'center'}}> Terms of Use Privacy Policy (updated May 24, 2018) <br/>
									<span style={{color: '#dadada'}}>© 2019 MLB Advanced Media, LP. All rights reserved.</span></p>
							</div>
					</div>
			</div>
		)
	}
}

export default Footer;
