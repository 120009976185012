import React from 'react'
import { CONSTANTS } from '../../constants/index'
import { WelcomeNote } from "../../helpers/utils";
import './index.css'

class Header extends React.Component {
    render() {
        const welcomeNote = WelcomeNote(this.props.url);
        return (
            <div className="header">
                <h1 style={{ textAlign: 'center' }}> {CONSTANTS.header} </h1>
                <p style={{ textAlign: 'justify', margin: '0px 10px 0px 10px' }} dangerouslySetInnerHTML={{ __html: welcomeNote }} />
            </div>
        )
    }
}

export default Header;
