import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  filterRegistrations,
  getSorting,
  stableSort,
  styles,
  updateWinner,
  delWinner,
  isTokenExpired,
  removeLocalStorageItems
} from "../../helpers/utils";
import TableHeader from "./TableHeader";
import WinnerModal from "../Modal/WinnerModal";
import { selectWinner, editWinner, deleteWinner } from "../../reducers/winners";
import { getMemberDetails } from '../../reducers/players';
import "./index.css";
import { options } from "../../constants";
import { trophy } from "react-icons-kit/fa/trophy";
// import { edit } from "react-icons-kit/fa/edit";
import { user } from "react-icons-kit/fa/user";
// import { remove } from "react-icons-kit/fa/remove";
import Icon from "react-icons-kit";
import ReactTooltip from "react-tooltip";
import * as _ from "lodash";
import * as moment from "moment-timezone";
import Confirmation from "../Modal/Confirmation";

class PlayersTable extends React.Component {
  state = {
    pagination: {
      order: "desc",
      orderBy: "playerFirstName",
      page: 0,
      rowsPerPage: 10
    },
    data: [],
    winnerOpen: false,
    editWinnerOpen: false,
    isClicked: false,
    registrationId: "",
    playerFirstName: "",
    playerLastName: "",
    selection: "",
    winnerScore: "",
    parentEmail: "",
    parentPhone: "",
    parentName: "",
    token: "",
    role: undefined,
    site: "",
    openConfirmation: false,
    dataCount: 0
  };

  constructor(props) {
    super(props);
    this.state.pagination = props.pagination;
  }

  componentWillMount() {
    const role = localStorage.getItem("role");
    const site = localStorage.getItem("site");
    const { players = {} } = this.props;
    const { registrations = {} } = players;
    const { data = [], count } = registrations
    const newReg = role ? data : filterRegistrations(data)
    // TODO: check why the parentPhone for kid77 ain't displayed the first time when it's there in winner document

    this.setState({
      data: newReg,
      role,
      site,
      dataCount: count
    });
  }

  componentWillReceiveProps(newProps) {
    const role = localStorage.getItem("role");
    const site = localStorage.getItem("site");

    const { players = {} } = newProps;
    let { registrations = {} } = players;
    const { data = [], count } = registrations
    let newReg = role ? data : filterRegistrations(data)

    // TODO: update parentPhone in Firestore
    // TODO: handle case where parent phone doesn't exist in member either (some dialogbox?)

    this.setState({
      pagination: newProps.pagination,
      data: newReg,
      role,
      site,
      dataCount: count
    });
  }

  handleRequestSort = (event, orderBy) => {
    const {pagination: {orderBy: _orderBY, order: _order}, pagination} = this.state;
    let order = "desc";

    if (_orderBY === orderBy && _order === "desc") {
      order = "asc";
    }

    this.setState({pagination: {...pagination, order, orderBy}}, () => {
      const {pagination: {page, rowsPerPage}, role} = this.state;
      const { refreshPlayers, updatePageNo } = this.props
      role && refreshPlayers({ page, order, orderBy, pageSize: rowsPerPage });
      updatePageNo({order, orderBy, page, rowsPerPage});
    });
  };

  handleChangePage = (event, page) => {
    const {pagination} = this.state;
    pagination.page = page;
    this.setState({pagination}, () => {
      const {pagination: {page, rowsPerPage, order, orderBy}, role} = this.state;
      const { refreshPlayers, updatePageNo } = this.props
      role && refreshPlayers({ page, order, orderBy, pageSize: rowsPerPage });
      updatePageNo(pagination);
    });
  };

  handleChangeRowsPerPage = event => {
    const rowsPerPage = event.target.value

    const {pagination} = this.state;
    pagination.rowsPerPage = rowsPerPage;
    this.setState({pagination}, () => {
      const {pagination: {page, rowsPerPage, order, orderBy}, role} = this.state;
      const { refreshPlayers, updatePageNo } = this.props
      role && refreshPlayers({ page, order, orderBy, pageSize: rowsPerPage });
      updatePageNo(pagination);
    });
  };

  handleClickOpen = (player) => {
    const res = isTokenExpired();
    const { site } = this.state;
    if (res) {
      removeLocalStorageItems();
      const { logout } = this.props;
      logout();
      if (site && _.isEqual(site, "pitchhitrun")) {
        this.props.history.push("/");
      } else {
        this.props.history.push("/jrhd");
      }
    }

    this.setState({
      player,
      winnerOpen: true
    });
  };

  handleWinnerClose = isOpen => {
    this.setState({ winnerOpen: isOpen });
  };

  handleEditClose = isOpen => {
    this.setState({
      editWinnerOpen: isOpen,
      selection: "",
      winnerScore: ""
    });
  };

  handleEditOpen = ({
    playerFirstName,
    playerLastName,
    selection,
    winnerScore,
    registrationId,
    parentEmail,
    parentName
  }) => {
    const { site } = this.state;
    if (isTokenExpired()) {
      removeLocalStorageItems();
      const { logout } = this.props;
      logout();
      if (site && _.isEqual(site, "pitchhitrun")) {
        this.props.history.push("/");
      } else {
        this.props.history.push("/jrHD");
      }
    }
    this.setState({
      parentEmail,
      parentName,
      playerFirstName,
      playerLastName,
      editWinnerOpen: true,
      selection: selection,
      winnerScore: winnerScore,
      registrationId: registrationId
    });
  };

  winnerEditHandleSubmit = (
    winnerType,
    winnerScore,
    registrationId,
    parentEmail,
    parentName,
    playerFirstName,
    playerLastName
  ) => {
    const { players, editWinnerRequest } = this.props;
    editWinnerRequest({
      winnerType,
      winnerScore,
      registrationId,
      parentEmail,
      parentName,
      playerFirstName,
      playerLastName
    });
    const { registrations = {} } = players
    const { data = [] } = registrations
    const newData = updateWinner(
      data,
      registrationId,
      winnerType,
      winnerScore
    );
    const filteredData = filterRegistrations(newData);

    this.setState({
      data: filteredData
    });
    this.setState({ editWinnerOpen: false, selection: "", winnerScore: "" });
  };

  winnerHandleSubmit = (
    winner
  ) => {
    const { player } = this.state;
    const { players, winnerRequest } = this.props;

    // const data = {
    //   winnerType,
    //   winnerScore,
    //   registrationId,
    //   parentEmail,
    //   parentName,
    //   playerFirstName,
    //   playerLastName,
    //   parentPhone
    // };

    winnerRequest({ ...player, ...winner });

    const registrations = _.cloneDeep(players.registrations.data);
    const item = _.remove(registrations, { registrationId: player.registrationId });

    const registration = item[0] || {};

    const winners = [];
    const scores = [];
    _.forEach(winner.winnerType, winner => {
      winners.push(Object.keys(winner)[0]);
      scores.push(winner[Object.keys(winner)[0]]);
    });

    registration.scores = scores;
    registration.winners = winners;
    registrations.push(registration);

    // const newData = updateWinner(
    //   registrations,
    //   registrationId,
    //   winnerType,
    //   winnerScore
    // );
    const filteredData = filterRegistrations(registrations);
    this.setState({
      data: filteredData,
      winnerOpen: false
    });
  };

  removeWinnerHandler = () => {
    const { programId, registrationId } = this.state;
    const { site } = this.state;
    const { history, deleteWinnerRequest, players } = this.props;
    if (isTokenExpired()) {
      removeLocalStorageItems();
      const { logout } = this.props;
      logout();
      history.push(`/${site}`);
    }

    deleteWinnerRequest({ programId, registrationId });
    const { registrations = {} } = players;
    const { data = [] } = registrations;
    const newData = delWinner(data, registrationId);
    const filteredData = filterRegistrations(newData);
    this.setState({
      data: filteredData,
      openConfirmation: false
    });
  };

  deleteWinnerHandler = (programId, registrationId) => {
    this.setState({ programId, registrationId, openConfirmation: true });
  };

  closeConfirmation = () => {
    this.setState({ openConfirmation: false });
  };

  getParentPhone = (event) => {
    this.props.getMemberDetails({ registrationId: event.target.name });
  };

  render() {
    const { classes } = this.props;
    const { data, dataCount } = this.state;
    const {
      pagination: { order,
        orderBy,
        rowsPerPage,
        page},
      winnerOpen,
      // playerFirstName,
      // playerLastName,
      // registrationId,
      // winnerScore,
      // selection,
      // editWinnerOpen,
      // parentEmail,
      // parentName,
      // parentPhone,
      role,
      openConfirmation
    } = this.state;

    const site = localStorage.getItem('site');
    const isJrHd = _.isEqual(site, 'jrhd');

    const tableData = role ? data : stableSort(data, getSorting(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    return (
      <Paper className={classes.root}>

        <Confirmation
          message={`Do you really want to remove this winner? `}
          open={openConfirmation}
          cancelRemoveHandler={this.closeConfirmation}
          removeHandler={this.removeWinnerHandler}
        />

        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              role={role}
            />
            <TableBody>
              {tableData.map((item, index) => {
                  const { selection } = item;
                  return (
                    <TableRow hover key={`row-${index}`}>
                      <TableCell
                        className="view-selection-style table-cell"
                        align="center">
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                          {
                            !role &&
                            <button
                              className="btnAsLink"
                              onClick={() => this.handleClickOpen(item)}
                              data-tip
                              data-for="trophy"
                              disabled={!_.isEmpty(selection)}
                            >
                              <Icon icon={trophy} size={20} />
                              <ReactTooltip id="trophy">
                                <span>Select Winner</span>
                              </ReactTooltip>
                            </button>
                          }
                          {role &&
                            <a href={`${options.viewPlayerApi}/${options.site_id}/memberDetails?memberId=${item.userId}`}
                              target='_blank'
                              rel="noopener noreferrer"
                              data-tip data-for='view'>
                              <Icon icon={user} size={20} />
                              <ReactTooltip id='view'>
                                <span> View Player</span>
                              </ReactTooltip>
                            </a>
                          }
                        </div>
                      </TableCell>
                      <TableCell
                        className="table-cell"
                        component="th"
                        align="left"
                        scope="row"
                        padding="none"
                      >
                        {item.playerFirstName}
                      </TableCell>
                      <TableCell className="table-cell" align="left">{item.playerLastName}</TableCell>
                      <TableCell className="table-cell" align="left">{item.parentName}</TableCell>
                      {!role && (<TableCell className="table-cell" align="left">
                        {item.birthDate && moment.tz(item.birthDate, "America/New_York").format("L")}
                      </TableCell>)}
                      <TableCell className="table-cell parent-phone" align="left">
                        {(!_.isEmpty(item.parentPhone) && item.parentPhone) ||
                          <button className="phoneButton" name={item.registrationId} onClick={this.getParentPhone}>Show Phone Number</button>}
                      </TableCell>
                      <TableCell className="table-cell" align="left">{item.parentEmail}</TableCell>
                      {role && (<TableCell className="table-cell" align="left">
                        {item.birthDate && moment.tz(item.birthDate, "America/New_York").format("L")}
                      </TableCell>)}
                      {role && (
                        <TableCell className="table-cell" align="left">{item.programName}</TableCell>
                      )}
                      {role && !isJrHd && (
                        <TableCell className="table-cell" align="left">{item.division}</TableCell>
                      )}
                      <TableCell className="table-cell" align="left">{item.selection}</TableCell>
                      {role && (
                        <TableCell className="table-cell" align="left">{item.winnerScore}</TableCell>
                      )}
                      {role && (
                        <TableCell className="table-cell" align="left">{item.ageGroup}</TableCell>
                      )}
                      {role && (
                        <TableCell className="table-cell" align="left">{item.created}</TableCell>
                      )}
                      {role && (
                        <TableCell className="table-cell" align="left">{(item.lastUpdated && moment.tz(item.lastUpdated, "America/New_York").format('MM/DD/YY HH:mm:ss')) || ''}</TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <WinnerModal
            open={winnerOpen}
            handleClick={this.handleWinnerClose}
            handleSubmit={this.winnerHandleSubmit}
            player={this.state.player}
          />
          {/* <EditWinnerModal
            open={editWinnerOpen}
            handleClick={this.handleEditClose}
            handleSubmit={this.winnerEditHandleSubmit}
            playerFirstName={playerFirstName}
            playerLastName={playerLastName}
            selection={selection}
            winnerScore={winnerScore}
            registrationId={registrationId}
            parentEmail={parentEmail}
            parentName={parentName}
          /> */}
        </div>
        <TablePagination
          className="table-pagination"
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={dataCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

PlayersTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    players: state.players
  }
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      getMemberDetails,
      winnerRequest: selectWinner,
      editWinnerRequest: editWinner,
      deleteWinnerRequest: deleteWinner
    }
  )(PlayersTable)
);
