
import { select, put, call } from 'redux-saga/effects';
import { getPlayers, getMembers, getMemberDetails, getProgramName, getWinners }  from '../reducers/players'
import API from '../services/ApiCaller';
import * as _ from 'lodash';

const getPlayersFromState = state => state.players;
export function* playersRequest(api, {payload: data = {} }) {
    try {
        
        const players = yield select(getPlayersFromState);
        if(players && players.registrations && !_.isEmpty(players.registrations)) {
            yield put(getPlayers.success(players));
        } else {
            const {res= {}} = yield call(API.callServer, api.playersApi, data);
            if (res.statusCode && res.statusCode === 401) {
                yield put(getPlayers.failure());
            }
            yield put(getPlayers.success({registrations: res, loading: false}));
         }
    } catch (e) {
        yield put(getPlayers.failure(e.message));
    }
}

export function* refreshPlayersRequest(api, {payload: data = {} }) {
    const {res= {}} = yield call(API.callServer, api.playersApi, data);
    if (res.statusCode && res.statusCode === 401) {
        yield put(getPlayers.failure());
    }
    yield put(getPlayers.success({registrations: res, loading: false}));
}

export function* membersRequest(api, {payload: data = {} }) {
    try {
        const { res = {} } = yield call(API.callServer, api.membersApi, data);
        yield put(getMembers.success(res));
    } catch (e) {
        yield put(getMembers.failure(e.message));
    }
}

export function* memberDetailsRequest(api, {payload: data = {} }) {
    try {
        const { res = {} } = yield call(API.callServer, api.memberDetailsApi, data);
        yield put(getMemberDetails.success(res));
    } catch (e) {
        yield put(getMemberDetails.failure(e.message));
    }
}

export function* programsRequest(api, {payload: data = {} }) {
	try {
		const { res = {} } = yield call(API.callServer, api.programsApi, data);
		yield put(getProgramName.success(res));
	} catch (e) {
		yield put(getProgramName.failure(e.message));
	}
}

export function* getWinnersRequest(api, {payload: data = {} }) {
	try {
		const { res = {} } = yield call(API.callServer, api.winnersApi, data);
		yield put(getWinners.success(res));
	} catch (e) {
		yield put(getWinners.failure(e.message));
	}
}



