import apisauce from 'apisauce';
import {API, API_UPLOAD_FILE} from '../constants/urls';

const create = (baseURL = API) => {
	const api = apisauce.create({
		baseURL,
		headers: {
			'Cache-Control': 'no-cache'
		},
		timeout: 300000
	});

	api.addRequestTransform(request => {
		request.params = request.params || [];
		request.params['_'] = Date.now();
		const siteName = document.location && document.location.pathname && document.location.pathname.replace("/", "").split("/")[0];
		request.headers['X-Site'] = siteName;
	})

	// Login Apis
	const loginApi = (data = {}) => api.post('/auth', data);
	const loginTroubleApi = (data = {}) => api.post('/auth/login-trouble', data);
	
	// Winners Api
	const winnersApi = (data = {}) => api.post('/winners', data);
	const editWinnersApi = (data = {}) => api.post('/winners/edit', data);
	const deleteWinnersApi = (data = {}) => api.post(`/winners/remove`, data);
	
	// PlayersApi
	const playersApi = (data = {}) => api.get('/players', data);
	const membersApi = (data = {}) => api.get('/players/details', data);
	const memberDetailsApi = (data = {}) => api.get('/players/memberDetails', data);
	const programsApi = (data = {}) => api.get('/players/programs', data);
	const getWinnersApi = (data = {}) => api.get('/players', data);
	
	// adminApi
	
	const getAdminsApi = (data = {}) => api.post('/users', data);
	const deleteAdminApi = (data = {}) => api.post('/users/delete', data);
	const editAdminApi = (data = {}) => api.put('/users/edit', data);
	
	return {
		...api,
		loginApi,
		loginTroubleApi,
		playersApi,
		winnersApi,
		editWinnersApi,
		deleteWinnersApi,
		membersApi,
		memberDetailsApi,
		programsApi,
		getWinnersApi,
		getAdminsApi,
		editAdminApi,
		deleteAdminApi
	};
};


const uploadFile = (baseURL = API_UPLOAD_FILE) => {
	const uploadFileApi = apisauce.create({
		baseURL,
		headers: {
			'Cache-Control': 'no-cache',
			'Content-Type': 'multipart/form-data'
		},
		timeout: 300000
	});
	
	uploadFileApi.addRequestTransform(request => {
		request.params = request.params || [];
		request.params['_'] = Date.now();
		const siteName = document.location && document.location.pathname && document.location.pathname.replace("/", "").split("/")[0];
		request.headers['X-Site'] = siteName;
	})
	//File upload
	const fileUploadApi = (data = {}) => uploadFileApi.post('/', data);
	
	return {
		...uploadFileApi,
		fileUploadApi
	};
};


export default {
	create,
	uploadFile
};

