import {createRoutine} from 'redux-saga-routines';
import {isEqual, map} from 'lodash';

export const GET_ADMINS = createRoutine('GET_ADMINS');
export const EDIT_ADMIN = createRoutine('EDIT_ADMIN');
export const DELETE_ADMIN = createRoutine('DELETE_ADMIN');
export const CLEAR_ADMIN = createRoutine('CLEAR_ADMIN');
export const CLEAR_EDIT_ADMIT_STATE = createRoutine('CLEAR_EDIT_ADMIT_STATE');

const INITIAL_STATE = {
	users: {}, loading: false, message: ''
};

const admin = (state = INITIAL_STATE, {type, payload}) => {
	
	switch (type) {
		case CLEAR_ADMIN.TRIGGER:
			return INITIAL_STATE;
		case GET_ADMINS.TRIGGER:
			return {...state, users: {}, loading: true, message: ''};
		case GET_ADMINS.SUCCESS:
			return {...state, users: {...payload}};
		case GET_ADMINS.FAILURE:
			return {...state, message: payload};
		case GET_ADMINS.FULFILL:
			return {...state, loading: false};
		
		case EDIT_ADMIN.TRIGGER:
			return {...state, loading: true, message: '', dupData: {}};
		case EDIT_ADMIN.SUCCESS:
			let {users = []} = state;
			let {data = []} = users || {};
			const {data: newData = {}} = payload;
			data = data.map((item) => {
				const {id = ''} = item || {};
				if (id === newData.id) {
					item = newData;
				}
				return item
			});
			return {...state, users: {data, size: data.length}, success: true};
		case EDIT_ADMIN.FAILURE:
			const {success, message, data: dupData = {}} = payload || {};
			return {...state, message: message, success, dupData};
		case EDIT_ADMIN.FULFILL:
			return {...state, loading: false};
		
		case DELETE_ADMIN.TRIGGER:
			return {...state, loading: true, message: ''};
		case DELETE_ADMIN.FAILURE:
			return {...state, message: payload, loading: false};
			
		case CLEAR_EDIT_ADMIT_STATE.TRIGGER:
			const { users: preUsers} = state;
				return {message: '', users: preUsers, success: true};
		
		default:
			return state
		
	}
};

export default admin;
