/* eslint-disable no-use-before-define */
import { put, call } from 'redux-saga/effects';
import {logout} from '../reducers/user'

export class CustomError extends Error {
  constructor(statusCode = 503, data, ...params) {
    super(...params);

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, CustomError);
    }

    this.statusCode = statusCode;
    this.data = data;
  }
}

function* callServer(apiFunction, reqData) {
  const response = yield call(apiFunction, reqData, reqData.id);
  const {
    data: resData, ok = false, problem = 'TIMEOUT_ERROR', status = 500
  } = response || {};
  if (ok) {
    return { res: resData, statusCode: status };
  }
  const message = extractMessage(resData, problem);
  if (status === 401) {
    yield put(logout.trigger());
  }
  throw new CustomError(status, resData, message);
}

export const extractMessage = (resData, problem) => {
  try {
    if (resData) {
      if (typeof resData.error === 'object' && resData.error.message) {
        return resData.error.message;
      } if (resData.message) {
        return resData.message;
      } if (resData.error) {
        return resData.error;
      } if (resData) {
        return resData;
      }
      return getMessage(resData);
    }
    return getMessage(problem);
  } catch(ex) {
    console.error(ex);
  }
};

const getMessage = (error) => {
  if (error === 'TIMEOUT_ERROR') {
    return 'No Response From Server.';
  } if (error === 'CONNECTION_ERROR') {
    return 'Server Is Not Available.';
  } if (error === 'NETWORK_ERROR') {
    return 'Network not available.';
  }
  return 'Something went wrong. Please try again';
};

export default {
  callServer
};
