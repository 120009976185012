import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class Confirmation extends React.Component {
    
    handleClose = () => {
       const { cancelRemoveHandler } = this.props;
        cancelRemoveHandler();
    };

    handleConfirm = () => {
        const {removeHandler} = this.props;
        removeHandler();
    }   

    render() {
        const { open, message } = this.props;
        return (
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`Confirmation...!`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                           {message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirm} color="primary">
                            Remove
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    }
}

export default Confirmation;
