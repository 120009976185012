import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Home from "./Home";
import ParticipantsList from '../components/ItemListing/index'
import Admin from '../components/Admin/index';
import './Home.css';

class App extends Component {
	render() {
		return (
			<Switch>
				<Route path="/pitchhitrun/participant-list" exact component={ParticipantsList}/>
				<Route path="/jrhd/participant-list" exact component={ParticipantsList}/>
				<Route path="/pitchhitrun" exact component={Home}/>
				<Route path="/jrhd" exact component={Home}/>
				<Route path="/admin" exact component={Admin}/>
				<Route path="/" exact render={() => (<Redirect to="/pitchhitrun"/>)}/>
			</Switch>
		);
	}
}


export default App;
