import TextField from "@material-ui/core/TextField/TextField";
import React from "react";

export const division = ['Baseball', 'Softball'];
export const ageGroupPitchHitRun = ['Age 7/8', 'Age 9/10', 'Age 11/12', 'Age 13/14'];
export const ageGroupJrhd = ['12U', '14U'];

export const CONSTANTS = {
	main_1: 'Pitch Hit & Run',
	main_2: 'Jr. Home Run Derby',
	header: 'Winner Submission Portal',
	path_pitchHitRun: '/pitchhitrun/participant-list',
	path_jrHD: '/jrhd/participant-list',
	site_1: 'pitchhitrun',
	site_2: 'jrhd',
	listHeading: 'Participant Listing',
	adminListHeading: 'Submitting Winners',
	listHelpTextHeader: 'Need more detail',
	loginHelpTextHeader: 'Need help',
	login: 'Login',
	winnerModalHeader: 'Submit Winner',
	editModalHeader: 'Edit Winner',
};

export const tableHeadings = [
	{id: 'actions', numeric: false, disablePadding: false, label: 'Action'},
	{id: 'playerFirstName', numeric: false, disablePadding: true, label: 'Player First Name'},
	{id: 'playerLastName', numeric: false, disablePadding: true, label: 'Player Last Name'},
	{id: 'parentName', numeric: false, disablePadding: false, label: 'Parent Name'},
	{id: 'birthDate', numeric: true, disablePadding: false, label: 'Player DOB'},
	{id: 'parentPhone', numeric: false, disablePadding: false, label: 'Parent Phone'},
	{id: 'parentEmail', numeric: false, disablePadding: false, label: 'Parent Email'},
	{id: 'programName', numeric: false, disablePadding: false, label: 'Program Name'},
	{id: 'division', numeric: false, disablePadding: false, label: 'Division'},
	{id: 'selection', numeric: false, disablePadding: false, label: 'Winner Types'},
	{id: 'winnerScore', numeric: false, disablePadding: false, label: 'Winner Scores'},
	{id: 'ageGroup', numeric: false, disablePadding: false, label: 'Age Group'},
	{id: 'created', numeric: false, disablePadding: false, label: 'Date Created'},
	{id: 'lastUpdated', numeric: true, disablePadding: false, label: 'Last Updated'}
];

export const winnerTableHeadings = [
	{id: 'playerFirstName', numeric: false, disablePadding: true, label: 'Player First Name'},
	{id: 'playerLastName', numeric: false, disablePadding: true, label: 'Player Last Name'},
	{id: 'parentName', numeric: false, disablePadding: false, label: 'Parent Name'},
	{id: 'parentPhone', numeric: false, disablePadding: false, label: 'Parent Phone'},
	{id: 'parentEmail', numeric: false, disablePadding: false, label: 'Parent Email'},
	{id: 'selection', numeric: false, disablePadding: false, label: 'Selection'},
];

export const winnerTypes = [
	'Pitch',
	'Hit',
	'Run',
	'All-Around Champ',
	'Home Run Champ'
];

export const options = {
	viewPlayerApi: 'https://manager.leagueapps.com/console/sites',
	site_id: '4970',
};

export const headers = [
	{label: 'Player First Name', key: 'playerFirstName'},
	{label: 'Player Last Name', key: 'playerLastName'},
	{label: 'Player DOB', key: 'birthDate'},
	{label: 'Parent Name', key: 'parentName'},
	{label: 'Parent Phone', key: 'parentPhone'},
	{label: 'Parent Email', key: 'parentEmail'},
	{label: 'Program Name', key: 'programName'},
	{label: 'Division', key: 'division'},
	{label: 'Winner Type', key: 'winnerType'},
	{label: 'Winner Score', key: 'winnerScore'},
	{label: 'Age Group', key: 'ageGroup'},
	{label: 'Created Date', key: 'created'},
	{label: 'Last Updated', key: 'lastUpdated'},
	{label: 'View Player', key: 'playerProfileUrl'},
];

export const winnersData = [
	{
		playerFirstName: 'first Name',
		playerLastName: 'Last Name',
		parentName: 'Parent Name',
		parentPhone: 'Parent Phone',
		parentEmail: 'Parent Email',
		selection: 'Selection'
	},
	{
		playerFirstName: 'first Name',
		playerLastName: 'Last Name',
		parentName: 'Parent Name',
		parentPhone: 'Parent Phone',
		parentEmail: 'Parent Email',
		selection: 'Selection'
	},
];

export const ADMINS_CONSTANT = {
	ADMIN_PAGE: 'Admin Users'
};

export const ADMIN_TABLE_COLUMN_NAME = [
	{id: 'site', numeric: false, disablePadding: false, label: 'SiteId', sortable: true},
	{id: 'firstName', numeric: false, disablePadding: false, label: 'First Name', sortable: false},
	{id: 'email', numeric: false, disablePadding: false, label: 'Email', sortable: false},
	{id: 'programId', numeric: false, disablePadding: false, label: 'Program Id', sortable: false},
	{id: 'actions', numeric: false, disablePadding: false, label: 'Actions', action: true, sortable: false},
];

export const EDIT_ADMIN_FIELDS = [
	{id: 'site', label: 'SiteId', type: 'text', autoFocus: true},
	{id: 'firstName', label: 'First Name', type: 'text'},
	{id: 'email', label: 'Email Address', type: 'email'},
	{id: 'programId', label: 'ProgramId', type: 'text'},
];

export const PAGINATION = {
	page: 0,
	pageSize: 100
};


export const TROUBLE_LOGGING_FIELDS = [
	{id: 'eventName', label: 'Event Name', type: 'text', autoFocus: true},
	{id: 'firstName', label: 'First Name', type: 'text'},
	{id: 'email', label: 'Email Address', type: 'email'},
	{id: 'eventId', label: 'EventId', type: 'text'},
	{id: 'comments', label: 'Comments/Additional Questions', type: 'text', multiline: true, rows: 4},
];
