
import { put, call } from 'redux-saga/effects';
import {GET_ADMINS, DELETE_ADMIN, EDIT_ADMIN}  from '../reducers/admin'
import API from '../services/ApiCaller'
import {PAGINATION} from "../constants";

export function* getAdminsRequest(api, {payload: data = {} }) {
	try {
		const { res = {} } = yield call(API.callServer, api.getAdminsApi, data);
		yield put(GET_ADMINS.success(res));
	} catch (e) {
		yield put(GET_ADMINS.failure(e.message));
	} finally {
		yield put(GET_ADMINS.fulfill());
	}
}


export function* editAdminRequest(api, {payload: data = {} }) {
	try {
		const { res = {} } = yield call(API.callServer, api.editAdminApi, data);
		const { success = false, data: resData, message } = res || {};
		if(success){
			yield put(EDIT_ADMIN.success({data: resData}));
		} else {
			yield put(EDIT_ADMIN.failure({data: resData, success, message}))
		}
	} catch (e) {
		yield put(EDIT_ADMIN.failure(e.message));
	} finally {
		yield put(EDIT_ADMIN.fulfill());
	}
}

export function* deleteAdminRequest(api, {payload: data = {} }) {
	try {
		const { res = {} } = yield call(API.callServer, api.deleteAdminApi, data);
		const { success = false} = res || {};
		if(success){
			yield put(GET_ADMINS.trigger({...PAGINATION}));
		}
	} catch (e) {
		yield put(DELETE_ADMIN.failure(e.message));
	} finally {
		yield put(DELETE_ADMIN.fulfill());
	}
}
