import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {ADMINS_CONSTANT, CONSTANTS, PAGINATION} from "../../constants";
import {Button, TextField} from '@material-ui/core';
import {DatePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import Table from './Table/index';
import {CLEAR_ADMIN, GET_ADMINS} from "../../reducers/admin";
import {logout} from '../../reducers/user';
import Footer from "../Footer";
import {isEqual} from 'lodash';
import './index.css';
import {Redirect} from "react-router-dom";
import {checkLocalStorage} from "../../sagas/user";
import {isLocalStorageItemExists} from "../../helpers/utils";


class Admin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			programId: '',
			siteId: '',
			date: {
				from: null,
				to: null
			},
			isEditOpen: false,
			pagination: PAGINATION
		}
	}
	
	componentWillMount() {
		const {history = {}, getAdminsRequest} = this.props;
		const {pagination: {pageSize} = {}} = this.state;
		const token = localStorage.getItem('access_token');
		if (!token) {
			history.push('/');
			return null;
		}
		
		getAdminsRequest({pageSize});
	}
	
	onHandleEmail = (event) => {
		const {target: {value: email = ''} = {}} = event || {};
		this.setState({email})
	};
	
	onHandleProgramId = (event) => {
		const {target: {value: programId = ''} = {}} = event || {};
		this.setState({programId})
	};
	
	onHandleSiteId = (event) => {
		const {target: {value: siteId = ''} = {}} = event || {};
		this.setState({siteId})
	};
	
	onHandleFromDate = (value) => {
		const date = Object.assign({}, this.state.date, {'from': value});
		this.setState({date})
	};
	
	onHandleToDate = (value) => {
		const date = Object.assign({}, this.state.date, {'to': value});
		this.setState({date})
	};
	
	onFormSubmit = (event) => {
		event.preventDefault();
		const {getAdminsRequest, history} = this.props;
		checkLocalStorage();
		const isToken = isLocalStorageItemExists('access_token');
		if(!isToken){
			history.push('/');
			return null;
		}
		
		const {email, programId, siteId, date, pagination: {order, orderBy, ...rest}} = this.state;
		this.setState({pagination: rest});
		getAdminsRequest({email, programId, siteId, date, ...rest});
		return false;
	};
	
	logoutHandler = () => {
		const {logout, history, clear} = this.props;
		clear();
		logout();
		history.push(`/`);
	};
	
	handlePagination = (pagination) => {
		this.setState({pagination});
	};
	
	_renderForm = () => {
		const {email, programId, date, siteId} = this.state;
		return (
			<form style={{display: 'flex', flexDirection: 'row', marginTop: '10px', flexWrap: 'wrap'}}>
				<div className="inputs" style={{display: 'flex', flexDirection: 'column', minWidth: '328px'}}>
					<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
						
						<TextField
							onChange={this.onHandleEmail}
							style={{minWidth: '280px', width: '100%', margin: '3px 10px', flex: 1}}
							value={email}
							variant="outlined"
							label="Email"
							id='email'
						/>
						
						<TextField
							onChange={this.onHandleProgramId}
							style={{minWidth: '280px', width: '100%', margin: '3px 10px', flex: 1}}
							variant="outlined"
							label="ProgramId"
							value={programId}
						/>
					</div>
					<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
						<div id='date-picker' style={{
							minWidth: '300px',
							width: '100%',
							margin: '0px 10px',
							flex: 1,
							display: 'flex',
							justifyContent: 'space-between'
						}}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									style={{maxWidth: '150px', width: '100%', flex: 1, marginTop: '5px', marginRight: '10px'}}
									margin="normal"
									label="Created date from"
									variant="outlined"
									value={date['from']}
									format="MM/dd/yyyy"
									onChange={this.onHandleFromDate}
								/>
								
								<DatePicker
									style={{maxWidth: '150px', width: '100%', flex: 1, marginTop: '5px', marginLeft: '10px'}}
									margin="normal"
									label="Created date to"
									variant="outlined"
									value={date['to']}
									format="MM/dd/yyyy"
									onChange={this.onHandleToDate}
								/>
							</MuiPickersUtilsProvider>
						</div>
						<div style={{minWidth: '300px', width: '100%', margin: '0px 10px', flex: 1, display: 'flex'}}>
							<TextField
								onChange={this.onHandleSiteId}
								style={{minWidth: '300px', width: '100%', margin: '5px 0px', flex: 1}}
								variant="outlined"
								label="SiteId"
								value={siteId}
							/>
						</div>
					</div>
				</div>
				<div
					style={{margin: '0px 10px', display: 'flex', flex: '1', flexDirection: 'column', justifyContent: 'flex-end'}}>
					<Button
						className='admin-search-btn'
						style={{margin: '10px 10px', alignSelf: 'flex-start'}}
						variant="contained"
						type='button' onClick={this.onFormSubmit}>
						Search
					</Button>
				</div>
			</form>
		)
	};
	
	_renderAdminTable = () => {
		const {email, programId, siteId, date} = this.state;
		const {history} = this.props;
		const role = localStorage.getItem('role');
		const site = localStorage.getItem('site');
		
		if (!role && isEqual(site, CONSTANTS.site_1)) {
			history.push('/pitchhitrun/participant-list');
			return null;
		} else if (!role && isEqual(site, CONSTANTS.site_2)) {
			history.push('/jrhd/participant-list');
			return null;
		}
		
		return (
			<div className='container p-0 m-0' id="admin-container" style={{display: 'flex', flexDirection: 'column'}}>
				<div className='d-flex w-100 justify-content-center' style={{flex: 1}}>
					<div className='w-100 m-3' style={{display: 'flex', flexDirection: 'column', flex: '1'}}>
						<div className='justify-content-center' id='header' style={{display: 'flex', flexDirection: 'row'}}>
							<h1 className="heading" style={{flex: 1}}> {ADMINS_CONSTANT.ADMIN_PAGE} </h1>
							<Button className="logout-btn-style" style={{alignSelf: 'center', display: 'inline'}}
							        onClick={this.logoutHandler}> Logout </Button>
						</div>
						
						<div id="admin-content" style={{display: 'flex', flexDirection: 'column', flex: 1}}>
							{this._renderForm()}
							<Table
								history={history}
								pagination={this.state.pagination}
								handlePagination={this.handlePagination}
								email={email}
								programId={programId}
								siteId={siteId}
								date={date}
							/>
						</div>
					</div>
				</div>
				<Footer/>
			</div>
		)
	};
	
	
	render() {
		const token = localStorage.getItem('access_token');
		return (
			<Fragment>
				{!token && <Redirect to = '/'/>}
				{this._renderAdminTable()}
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => (
	{
		clear: () => dispatch(CLEAR_ADMIN()),
		logout: () => dispatch(logout()),
		getAdminsRequest: (data = {}) => dispatch(GET_ADMINS(data))
	}
);

export default connect(null, mapDispatchToProps)(Admin);
