
import { call, put } from 'redux-saga/effects';
import { uploadFile } from '../reducers/upload_file'
import API from "../services/ApiCaller";

export function* uploadFileRequest(api, { payload: { file } }) {
	try {
		const data = new FormData();
		data.append('file', file);
		const response = yield call(API.callServer, api.fileUploadApi, data);
		yield put(uploadFile.success(response));
	} catch (ex) {
		console.error(ex);
		yield put(uploadFile.failure(ex.message));
	}
}