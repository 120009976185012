
import { put, call } from 'redux-saga/effects';
import { selectWinner, editWinner } from '../reducers/winners'
import { updatePlayer } from '../reducers/players';
import API from '../services/ApiCaller'

export function* winnersRequest(api, { payload: data = {} }) {
    try {
        const { res = {} } = yield call(API.callServer, api.winnersApi, data);
        yield put(selectWinner.success(res));
        yield put(updatePlayer.success(res));
    } catch (e) {
        yield put(selectWinner.failure(e.message));
    }
}

export function* editWinnersRequest(api, { payload: data = {} }) {
    try {
        const { res = {} } = yield call(API.callServer, api.editWinnersApi, data);
        yield put(editWinner.success(res));
    } catch (e) {
        yield put(editWinner.failure(e.message));
    }
}

export function* deleteWinnersRequest(api, { payload: data = {} }) {
    try {
        const { res = {} } = yield call(API.callServer, api.deleteWinnersApi, data);
        yield put(editWinner.success(res));
    } catch (e) {
        yield put(editWinner.failure(e.message));
    }
}

