import 'bootstrap/dist/css/bootstrap.min.css';
import 'babel-polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './containers/App';
import store from './store'
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { appStartup } from './reducers/startup';

let persistor = persistStore(store, null, () => {
	store.dispatch(appStartup());
	ReactDOM.render(<Root/>, document.getElementById('root'));
});

const Root = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<App/>
			</BrowserRouter>
		</PersistGate>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
