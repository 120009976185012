import React, {Component} from 'react';
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel/TableSortLabel";
import {withStyles} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell/TableCell";

const CustomTableHeaderCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.common.black,
		top: 0
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

class TableHeader extends Component{
	
	createSortHandler = property => event => {
		if (property !== 'actions') {
			this.props.onRequestSort(event, property);
		}
	};
	render() {
		const {orderBy, order, columns} = this.props;
		return (
			<TableHead>
				<TableRow>
					{columns && columns.map(
						row => (
							<CustomTableHeaderCell
								id='table-head-content'
								key={row.id}
								align={row.numeric || row.action ? 'center' : 'left'}
								padding={row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
							>
							{row.sortable &&
								<Tooltip
									title="Sort"
									placement={row.numeric ? 'bottom-end' : 'bottom-start'}
									disableHoverListener={!(row.id !== 'actions')}
									disableFocusListener={!(row.id !== 'actions')}
									enterDelay={300}
								>

										<TableSortLabel
											active={orderBy === row.id}
											direction={order}
											onClick={this.createSortHandler(row.id)}
										>
											{row.label}
										</TableSortLabel>

								</Tooltip> ||
								<span>{row.label}</span>
							}
							</CustomTableHeaderCell>
						),
						this,
					)}
				</TableRow>
			</TableHead>
		)
	}
}

export default TableHeader;
