import {combineReducers} from 'redux';
import user from './user';
import players from './players';
import winners from './winners';
import file from './upload_file';
import admins from './admin';


const rootReducer = combineReducers({
	user,
	players,
	winners,
	file,
	admins
});

export default rootReducer;
