import {createRoutine} from 'redux-saga-routines';

export const selectWinner = createRoutine('SELECT_WINNER');
export const editWinner = createRoutine('EDIT_WINNER');
export const deleteWinner = createRoutine('DELETE_WINNER');

const INITIAL_STATE = {
	message: '', loading: false, winners: [], fetchWinners: false
};

const winners = (state = INITIAL_STATE, {type, payload}) => {
	const {
		message = '',
	} = payload || '';
	
	switch (type) {
		case selectWinner.TRIGGER:
			return {...state, loading: true};
		case selectWinner.SUCCESS:
			return {
				...state,
				message: message,
				loading: false
			};
		case selectWinner.FAILURE:
			localStorage.removeItem('access_token');
			localStorage.removeItem('email');
			localStorage.removeItem('firstName');
			localStorage.removeItem('programId');
			localStorage.removeItem('site');
			return {
				...state, loading: false
			};
		
		case editWinner.TRIGGER:
			return {...state, loading: true};
		
		case editWinner.SUCCESS:
			return {
				...state,
				message: message,
				loading: false
			};
		case editWinner.FAILURE:
			localStorage.removeItem('access_token');
			localStorage.removeItem('email');
			localStorage.removeItem('firstName');
			localStorage.removeItem('programId');
			localStorage.removeItem('site');
			return {
				...state, loading: false
			};
		
		case deleteWinner.TRIGGER:
			return {...state, loading: true};
		
		case deleteWinner.SUCCESS:
			return {
				...state,
				message: message,
				loading: false
			};
		case deleteWinner.FAILURE:
			localStorage.removeItem('access_token');
			localStorage.removeItem('email');
			localStorage.removeItem('firstName');
			localStorage.removeItem('programId');
			localStorage.removeItem('site');
			return {
				...state, loading: false
			};
		
		default:
			return state
		
	}
};

export default winners;
