import * as _ from 'lodash';

import {
    createRoutine
} from 'redux-saga-routines';
export const getPlayers = createRoutine('GET_PLAYERS');
export const getMembers = createRoutine('GET_MEMBERS');
export const getMemberDetails = createRoutine('GET_MEMBER_DETAILS');
export const getProgramName = createRoutine('GET_PROGRAM_NAME');
export const getWinners = createRoutine('GET_WINNERS');
export const updatePlayer = createRoutine('UPDATE_PLAYER');
export const refreshPlayers = createRoutine('REFRESH_PLAYERS');

const INITIAL_STATE = {
    registrations: [],
    loading: false,
    memberDetails: [],
    loadingMembers: false,
    programName: '',
    loadingPrograms: false,
    winners: [],
    fetchWinners: false
};

const players = (state = INITIAL_STATE, {
    type,
    payload
}) => {

    switch (type) {
        case refreshPlayers.TRIGGER: 
            return {
                ...state,
                loading: true
            }
        case getPlayers.TRIGGER: 
            return {
                ...state,
                loading: true
            }
        case getPlayers.SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false
            };
        case getPlayers.FAILURE:
            localStorage.removeItem('access_token');
            localStorage.removeItem('email');
            localStorage.removeItem('firstName');
            localStorage.removeItem('programId');
            localStorage.removeItem('site');
            return {
                ...state,
                loading: false
            };

            // GET MEMBER DETAILS
        case getMemberDetails.TRIGGER:
            return {
                ...state,
                loadingMembers: true
            };

        case getMemberDetails.SUCCESS:
            {
                let registrations = _.cloneDeep(state.registrations);
                debugger;
                registrations.data = _.map(registrations.data, reg => {
                    if (payload.registrationId === reg.registrationId) {
                        reg.parentPhone = payload.parentPhone || reg.parentPhone || 'NA';
                    }

                    return reg;
                });

                return {
                    ...state,
                    registrations,
                    memberDetails: payload,
                    loadingMembers: false
                };
            }
        case getMemberDetails.FAILURE:
            return {
                ...state,
                loadingMembers: false
            };

            //GET MEMBERS
        case getMembers.TRIGGER:
            return {
                ...state,
                loadingMembers: true
            };

        case getMembers.SUCCESS:
            {
                // const details = _.keyBy(payload, 'registrationId');

                // let registrations = _.cloneDeep(state.registrations);
                // registrations = _.map(registrations, reg => {
                //     const detail = details[reg.registrationId];
                //     if (detail) {
                //         reg.parentPhone = detail.parentPhone || reg.parentPhone || '';
                //     }

                //     return reg;
                // });

                return {
                    ...state,
                    /* registrations, */
                    memberDetails: payload,
                    loadingMembers: false
                };
            }
        case getMembers.FAILURE:
            return {
                ...state,
                loadingMembers: false
            };

            // GET PROGRAM NAME
        case getProgramName.TRIGGER:
            return {
                ...state,
                loadingPrograms: true
            };

        case getProgramName.SUCCESS:
            return {
                ...state,
                programName: payload,
                loadingPrograms: false
            };
        case getProgramName.FAILURE:
            return {
                ...state,
                loadingPrograms: false
            };

            // GET WINNERS
        case getWinners.TRIGGER:
            return {
                ...state,
                fetchWinners: false
            };

        case getWinners.SUCCESS:
            return {
                ...state,
                winners: payload,
                fetchWinners: true
            };

        case getWinners.FAILURE:
            return {
                ...state,
                fetchWinners: false
            };

            //UPDATE PLAYER
        case updatePlayer.SUCCESS:
            const {
                data
            } = payload;
            const {
                winnerType,
                registrationId,
                parentPhone
            } = data;

            const scores = [];
            const winners = [];
            _.forEach(winnerType, winner => {
                if (!_.isEmpty(winner)) {
                    scores.push(Object.values(winner)[0]);
                    winners.push(Object.keys(winner)[0]);
                }
            });
            const {
                registrations
            } = state;
            const player = _.find(registrations.data, {
                registrationId
            });

            player.winners = winners;
            player.scores = scores;
            player.parentPhone = (parentPhone && parentPhone) || 'NA';

            const _index = _.findIndex(registrations.data, {
                registrationId
            });

            registrations.data.splice(_index, 1, player);
            return {
                ...state,
                registrations
            };

        default:
            return state

    }
};

export default players;