import {all, takeLatest} from 'redux-saga/effects';
import * as urls from '../constants/urls'
import API from '../services/Api'

// Reducers

import {login, logout, SUBMIT_LOGIN_TROUBLE_REQUEST, switchEvent} from '../reducers/user'
import {getMemberDetails, getMembers, getPlayers, getProgramName, getWinners, refreshPlayers} from '../reducers/players'
import {appStartup} from '../reducers/startup'
import {clearState, uploadFile} from '../reducers/upload_file'
import {deleteWinner, editWinner, selectWinner} from '../reducers/winners'
import {DELETE_ADMIN, EDIT_ADMIN, GET_ADMINS} from '../reducers/admin';

//Sagas
import {startupAction} from './startup'
import {uploadFileRequest} from './upload_file'
import {loginRequest, loginSuccess, logoutRequest, submitLoggingTroubleRequest, switchEventRequest} from './user'
import {
	getWinnersRequest,
	memberDetailsRequest,
	membersRequest,
	playersRequest,
	programsRequest,
	refreshPlayersRequest
} from './players'
import {deleteWinnersRequest, editWinnersRequest, winnersRequest} from './winners'
import {deleteAdminRequest, editAdminRequest, getAdminsRequest} from './admin';


export const api = API.create(urls.API);
export const upload_file_api = API.uploadFile(urls.API_UPLOAD_FILE);

export default function* root() {
	yield all([
		
		takeLatest(appStartup.TRIGGER, startupAction, api),
		
		// User related triggers
		takeLatest(login.TRIGGER, loginRequest, api),
		takeLatest(login.SUCCESS, loginSuccess, api),
		takeLatest(logout.TRIGGER, logoutRequest, api),
		takeLatest(switchEvent.TRIGGER, switchEventRequest, api),
		takeLatest(SUBMIT_LOGIN_TROUBLE_REQUEST.TRIGGER, submitLoggingTroubleRequest, api),
		
		// Players related triggers
		takeLatest(getPlayers.TRIGGER, playersRequest, api),
		takeLatest(refreshPlayers.TRIGGER, refreshPlayersRequest, api),
		takeLatest(getMembers.TRIGGER, membersRequest, api),
		takeLatest(getMemberDetails.TRIGGER, memberDetailsRequest, api),
		takeLatest(getProgramName.TRIGGER, programsRequest, api),
		takeLatest(getWinners.TRIGGER, getWinnersRequest, api),
		
		//Winners related triggers
		takeLatest(selectWinner.TRIGGER, winnersRequest, api),
		takeLatest(editWinner.TRIGGER, editWinnersRequest, api),
		takeLatest(deleteWinner.TRIGGER, deleteWinnersRequest, api),
		
		// File upload
		takeLatest(uploadFile.TRIGGER, uploadFileRequest, upload_file_api),
		takeLatest(clearState.TRIGGER, () => {
		}, () => {
		}),
		
		//admin related triggers
		takeLatest(GET_ADMINS.TRIGGER, getAdminsRequest, api),
		takeLatest(EDIT_ADMIN.TRIGGER, editAdminRequest, api),
		takeLatest(DELETE_ADMIN.TRIGGER, deleteAdminRequest, api),
	])
}
