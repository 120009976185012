import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class UploadModel extends React.Component {
    state = {
        open: false,
    };

    handleClose = () => {
       const{ closeHandler } = this.props;
       const { open } = this.state;

        closeHandler(open)
    };

    render() {
        const { open, uploaded, error, isUploading } = this.props;
        return (
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`Alert...!`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {isUploading && `File is being uploaded, please wait...`}
                            {(uploaded && error) || error}
                            {uploaded && !isUploading && !error && `Admins are created successfully.` }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Exit
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    }
}

export default UploadModel;
