
import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { CONSTANTS } from '../../constants/index'
import { login, switchEvent } from '../../reducers/user'
import './index.css'
import LinearIndeterminate from "../Loading/LinearLoading";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import * as validator from "validator";
import * as _ from 'lodash'


class LoginFrom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            firstName: '',
            programId: '',
            email: '',
            emailError: '',
            programIdError: '',
            firstNameError: ''
        }
    }

    componentWillMount() {
        const email = localStorage.getItem('email') || '';
        const firstName = localStorage.getItem('firstName') || '';
        this.setState({ email, firstName })
    }

    emailFocus = () => {
        this.setState({ emailError: '' })
    };

    firstNameFocus = () => {
        this.setState({ firstNameError: '' })
    };


    programIdFocus = () => {
        this.setState({ programIdError: '' })
    };

    validate = (firstName, programId, email) => {
        const errors = {};
        if (firstName.length <= 0) errors.firstNameError = 'FirstName can\'t be blank';
        if (programId.length <= 0) errors.programIdError = 'Event Id can\'t be blank';
        if (!validator.isEmail(email)) errors.emailError = 'Email is invalid';
        return errors
    };

    onSubmitHandler = () => {
        const { firstName, programId, email } = this.state;
        const { emailError, programIdError, firstNameError } = this.validate(firstName, programId, email);
        if (!(emailError || programIdError || firstNameError)) {
            const { loginRequest, switchEventReq } = this.props;
            switchEventReq();
            loginRequest({ firstName, programId, email });
        }
        this.setState({ emailError, programIdError, firstNameError });
    };

    emailHandler = (event) => {
        this.setState({ email: event.target.value })
    };

    programIdHandler = (event) => {
        this.setState({ programId: event.target.value })
    };

    firstNameHandler = (event) => {
        this.setState({ firstName: event.target.value })
    };

    enterKeyHandler = (e) => {
        if (_.isEqual(e.key, 'Enter')) {
            const { firstName, programId, email } = this.state;
            const { emailError, programIdError, firstNameError } = this.validate(firstName, programId, email);
            if (!(emailError || programIdError || firstNameError)) {
                const { loginRequest } = this.props;
                loginRequest({ firstName, programId, email });
            }
            this.setState({ emailError, programIdError, firstNameError });
        }
    };

    render() {
        const { user } = this.props;
        const { programIdError, emailError, firstNameError } = this.state;
        const { loading, msg, error } = user || {};
        const token = localStorage.getItem('access_token');
        const site = localStorage.getItem('site');
        return (
            <Card className="card-content ">
                <CardContent>
                    {loading ? <LinearIndeterminate /> : null}
                    <div>
                        <h2 className="login-heading">{CONSTANTS.login}</h2>
                    </div>
                    <TextField
                        required
                        id="outlined-required"
                        label="Email Address"
                        type="email"
                        margin="normal"
                        variant="outlined"
                        className="text-field-width"
                        onChange={this.emailHandler}
                        defaultValue={this.state.email}
                        onFocus={this.emailFocus}
                        onKeyPress={this.enterKeyHandler}
                    />
                    {emailError && <FormHelperText className="error-style" error={true}> {emailError} </FormHelperText>}
                    <br />
                    <TextField
                        required
                        id="outlined-required"
                        label="First Name"
                        type="text"
                        margin="normal"
                        variant="outlined"
                        className="text-field-width"
                        onChange={this.firstNameHandler}
                        defaultValue={this.state.firstName}
                        onFocus={this.firstNameFocus}
                        onKeyPress={this.enterKeyHandler}
                    />
                    {firstNameError && <FormHelperText className="error-style" error={true}> {firstNameError} </FormHelperText>}
                    <br />
                    <TextField
                        required
                        id="outlined-required"
                        label="Event ID"
                        type="number"
                        margin="normal"
                        variant="outlined"
                        className="text-field-width"
                        onChange={this.programIdHandler}
                        defaultValue={this.state.programId}
                        onFocus={this.programIdFocus}
                        onKeyPress={this.enterKeyHandler}
                    />
                    {programIdError && <FormHelperText className="error-style" error={true}> {programIdError} </FormHelperText>}
                    <br />
                    {(token && site === CONSTANTS.site_1) && <Redirect to={CONSTANTS.path_pitchHitRun} />}
                    {(token && site === CONSTANTS.site_2) && <Redirect to={CONSTANTS.path_jrHD} />}
                    {error ? <FormHelperText className="server-error-style" error={true} > {msg} </FormHelperText> : ''}
                    <br />
                    <Button onClick={this.onSubmitHandler} variant="contained">
                        Submit
                    </Button>
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {
    loginRequest: login,
    switchEventReq: switchEvent
})(LoginFrom);
