import React, {Component} from 'react';
import {connect} from 'react-redux';
import Header from '../components/Header/index';
import LoginForm from '../components/LoginForm/index'
import Footer from '../components/Footer/index'
import TroubleLoginFormModal from "../components/TroubleLogginFormModal";
import {UPDATE_STATE} from "../reducers/user";
import './Home.css';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		}
	}
	
	onHandleModalOpen = () => {
		this.setState({isOpen: true})
	};
	
	onHandleUpdateModalOpen = (isOpen) => {
		this.setState({isOpen})
	};
	
	
	
	render() {
		const {location = {}, updateState} = this.props;
		const {pathname = ''} = location || {};
		return (
			<div className="container">
				<Header url={pathname}/>
				<br/>
				<LoginForm />
				<span id='logging-trouble' style={{fontSize: '14px', fontWeight: '400', fontFamily: 'Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif'}}>
					Trouble logging in? Please <a onClick={this.onHandleModalOpen}>click here</a> for assistance
				</span>
				<TroubleLoginFormModal
					isOpen={this.state.isOpen}
					handleUpdateModalOpen={this.onHandleUpdateModalOpen}
					updateState={updateState}
					location={location}
				/>
				<Footer/>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => (
	{
		updateState: () => dispatch(UPDATE_STATE())
	}
);
export default connect(null, mapDispatchToProps)(Home);
