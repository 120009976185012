import {call, put} from 'redux-saga/effects';
import {login, logout, SUBMIT_LOGIN_TROUBLE_REQUEST} from '../reducers/user'
import {getPlayers, getProgramName} from '../reducers/players'
import API from '../services/ApiCaller'
import * as jwt from 'jsonwebtoken'

export function* loginRequest(api, {payload: credentials}) {
	try {
		const {res = {}} = yield call(API.callServer, api.loginApi, credentials);
		const {token, site} = res;
		
		const decoded = jwt.decode(token);
		const {role = undefined} = decoded;
		
		const {firstName, email, programId} = credentials;
		localStorage.setItem('access_token', token);
		localStorage.setItem('firstName', firstName);
		localStorage.setItem('email', email);
		localStorage.setItem('programId', programId);
		localStorage.setItem('site', site);
		if (role) {
			localStorage.setItem('role', role);
		}
		yield put(login.success(res));
	} catch (e) {
		yield put(login.failure(e.message));
	}
}

export const checkLocalStorage = () => {
	const access_token = localStorage.getItem('access_token');
	if (access_token && jwt.decode(access_token)) {
		const expiry = jwt.decode(access_token).exp;
		const now = Date.now().valueOf() / 1000;
		const isExpired = now > expiry;
		if (isExpired) {
			localStorage.removeItem('access_token');
			localStorage.removeItem('email');
			localStorage.removeItem('firstName');
			localStorage.removeItem('programId');
		}
	}
};

export function* loginSuccess(api, state) {
	const {payload} = state;
	const {token = ''} = payload;
	api.setHeader('Authorization', `Bearer ${token}`);
	checkLocalStorage();
	const role = localStorage.getItem('role');
	yield put(getPlayers());
	if(!role){
		yield put(getProgramName());
	}
}

export function* logoutRequest(api) {
	try {
		yield put(logout.success());
		api.setHeader('Authorization', '');
		yield put(getPlayers.success({registrations: [], loading: false}));
	} catch (e) {
		console.error(e.message);
	}
}

export function* switchEventRequest(api) {
	try {
		yield put(getPlayers.success({registrations: [], loading: false}));
	} catch (e) {
		console.error(e.message);
	}
}


export function* submitLoggingTroubleRequest(api, {payload: data}) {
	try {
		const {res = {}} = yield call(API.callServer, api.loginTroubleApi, data);
		yield put(SUBMIT_LOGIN_TROUBLE_REQUEST.success(res));
	} catch (e) {
		yield put(SUBMIT_LOGIN_TROUBLE_REQUEST.failure(e.message));
	} finally {
		yield put(SUBMIT_LOGIN_TROUBLE_REQUEST.fulfill())
	}
}


