import React from "react";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import { tableHeadings } from "../../constants";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel/TableSortLabel";
import PropTypes from "prop-types";
import * as _ from 'lodash';

class TableHeader extends React.Component {

    createSortHandler = property => event => {
        if (property !== 'actions') {
            this.props.onRequestSort(event, property);
        }
    };

    componentWillMount() {
        const role = localStorage.getItem('role');
        const site = localStorage.getItem('site');

        const headings = _.cloneDeep(tableHeadings);
        if (!_.isEqual(role, 'admin')) {
            const headersToRemove = ['created', 'lastUpdated', 'programName', 'winnerScore', 'division', 'ageGroup'];
            _.remove(headings, header => _.includes(headersToRemove, header.id));
        } else {
            try {
                let _headersToRemove = ['birthDate'];
                const item = _.remove(headings, header => _.includes(_headersToRemove, header.id));
                headings.splice(6, 0, ...item);

                if (_.isEqual(site, 'jrhd')) {
                    _headersToRemove = ['division'];
                    _.remove(headings, header => _.includes(_headersToRemove, header.id));

                    _.forEach(headings, _header => {
                        if (_.isEqual(_header.id, 'winnerScore'))
                            _header.label = 'Number of Home Runs';
                    });
                }


            } catch (exception) {
                console.error(exception);
            }
        }

        this.setState({ headings });
    }

    render() {
        const { order, orderBy } = this.props;
        const { headings } = this.state;
        return (
            <TableHead>
                <TableRow>
                    {headings.map(row => {
                        return (
                            <TableCell
                                className="tableHeaders table-cell"
                                key={`header-${row.id}`}
                                align={row.id === 'actions' ? 'center' : 'left'}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                {(!_.includes(row.id, 'action') &&
                                    <Tooltip
                                        title="Sort"
                                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                        enterDelay={300}
                                        disableHoverListener={!(row.id !== 'actions')}
                                        disableFocusListener={!(row.id !== 'actions')}
                                    >
                                        <TableSortLabel
                                            active={orderBy === row.id}
                                            direction={order}
                                            onClick={this.createSortHandler(row.id)}
                                        >
                                            {row.label}
                                        </TableSortLabel>
                                    </Tooltip>) || row.label}
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

TableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default TableHeader;
