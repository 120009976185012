/* eslint-disable guard-for-in */
import {put} from 'redux-saga/effects';
import {login, ON_REFRESH_TRIGGER} from '../reducers/user'

export function* startupAction(api) {
	try {
		yield put(ON_REFRESH_TRIGGER.trigger());
		const access_token = localStorage.getItem('access_token');
		if (!access_token) {
			return null
		}
		yield put(login.success({token: access_token}))
	} catch (e) {
		console.error(e.message);
	}
}
