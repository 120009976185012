import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import { CONSTANTS, headers } from "../../constants";
import PlayersTable from './PlayersTable'
import CardContent from "@material-ui/core/CardContent";
import { ListingHelpText, getSiteName } from '../../helpers/utils'
import Button from "@material-ui/core/Button";
import './index.css'
import { Redirect } from "react-router-dom";
import ContentLoader from '../Loading/ContentLoader'
import { getMembers, refreshPlayers } from '../../reducers/players';
import { uploadFile, clearState } from '../../reducers/upload_file';
import { logout, switchEvent } from '../../reducers/user';
import Footer from '../../components/Footer/index';
import Typography from "@material-ui/core/Typography/Typography";
import UploadModel from '../Modal/UploadModel';
import * as _ from 'lodash';
import { API } from "../../constants/urls";

class PlayersList extends React.Component {
	constructor(props) {
		super(props);
		const isIE = /*@cc_on!@*/false || !!document.documentMode;

		this.state = {
			isIE,
			isExpired: false,
			role: undefined,
			site: undefined,
			siteName: '',
			file: '',
			uploaded: false,
			error: props.uploadError,
			open: false,
			pagination: {
				order: "desc",
				orderBy: "lastUpdated",
				page: 0,
				rowsPerPage: 10
			}
		};
	}

	updatePageNo = (pagination) => {
		this.setState({pagination});
	}

	componentDidMount() {
		const role = localStorage.getItem('role');
		const site = localStorage.getItem('site');
		this.setState({ role, site });
		// this.props.getMembers();

		const exportHeaders = _.cloneDeep(headers);
		if (!_.isEqual(role, 'admin')) {
			const headersToRemove = ['created', 'lastUpdated', 'programName', 'winnerScore', 'division', 'ageGroup'];
			_.remove(exportHeaders, header => _.includes(headersToRemove, header.key));
		}

		if (_.isEqual(role, 'admin')) {
			const headersToRemove = [''];
			_.remove(exportHeaders, header => _.includes(headersToRemove, header.key));

			if (_.isEqual(site, 'jrhd')) {
				_.forEach(exportHeaders, _header => {
					if (_.isEqual(_header.key, 'winnerScore'))
						_header.label = 'Number of Home Runs';
				})
			}
		}

		role && this.props.refreshPlayers();
		this.setState({ exportHeaders });
	}

	logoutHandler = () => {
		const { logout, history } = this.props;
		logout();
		history.push(`/${this.state.site}`);
	};

	eventSwitchHandler = () => {
		const { switchEvent, history } = this.props;
		localStorage.removeItem('access_token');
		localStorage.removeItem('programId');
		switchEvent();
		history.push(`/${this.state.site}`);
	};

	refreshList = () => {
		const button = document.getElementById('refresh_btn').style;
		button.pointerEvents = 'none';
		button.opacity = 0.4;
		this.props.refreshPlayers();

		setTimeout(() => {
			button.pointerEvents = 'auto';
			button.opacity = 1;
		}, 5000);
	};

	onChangeFile(event) {

		const { uploadFile } = this.props;
		let file = event.target.files[0];
		if (_.includes(file.name, 'csv') || _.includes(file.type, 'csv')) {
			this.setState({ open: true }, () => {
				uploadFile({ file });
			});
		} else {
			this.setState({ open: true, error: `Please! upload a valid CSV file.` });
		}

		if (event.target) {
			event.target.value = '';
			delete event.target.files;
		}

		event.stopPropagation();
		event.preventDefault();
	}

	closeDialog = (open) => {
		const {clearState} = this.props;
		clearState();
		this.setState({ open, error: '' });
	}

	static getDerivedStateFromProps(newProps) {
		const { upload, error } = newProps;
		if (!_.isEmpty(upload))
			return { upload, error };
		return null;
	}

	render() {
		const { players = {}, uploadError, isUploading, uploaded } = this.props;
		const { error, open, isIE, site, role, pagination } = this.state;
		const { loading, programName: { programName, registerUrlHtml, programUrlHtml },programName: _prog, registrations } = players;
		const siteName = getSiteName(registrations.data || []);
		const token = localStorage.getItem('access_token');
		const helpTextDetail = ListingHelpText(site, programUrlHtml);

		return (
			<div className="container-wrapper">
				<UploadModel open={open} error={uploadError || error} uploaded={uploaded} isUploading={isUploading} closeHandler={this.closeDialog} />
				<div className="participants-container">
					{!loading ?
						<div>
							{!token && <Redirect to='/' />}
							<div>
								<div className="participants-inner-container">
									<div className="heading-div-style">
										{_.isEqual(site, 'pitchhitrun')
											? <h1 className="heading"> {CONSTANTS.main_1} </h1>
											: <h1 className="heading"> {CONSTANTS.main_2} </h1>
										}
										<h4 className="heading" style={{ marginTop: '-5px' }}> {CONSTANTS.header} </h4>
									</div>
									<div className="logout-div-btn">
										{role &&
											<div>
												<Button className="import-btn-style" onClick={() => { this.upload.click() }}>Import Admins </Button>
												<input onChange={this.onChangeFile.bind(this)} type="file" ref={(ref) => this.upload = ref} style={{ display: 'none' }} id="file" />
											</div>
										}
										<Button className="logout-btn-style" onClick={this.logoutHandler}> Logout </Button>
									</div>
								</div>
								<Card>
									<CardContent className="participants-card-style">
										<div className="program-name-style">
											{!role ? <h2 className="program-name-heading"> {programName} </h2> : <h2 className="program-name-heading"> {siteName} </h2>}
										</div>
										<div className="participants-outerHeader-style">
											<div className="participants-innerHeader-style">
												<div className="participants-needHelp-style">
													<Typography dangerouslySetInnerHTML={{ __html: helpTextDetail }} />
												</div>
											</div>
											<div className="participants-btnDiv-style">
												{!role &&
													<Button id="refresh_btn" onClick={this.refreshList} className="participants-btn-style">
														Refresh
													</Button>
												}
												<form method='post' action={`${API}/players/downloadCSV`} target="_blank" className="participants-form-style" style={{flex:'unset'}}>
													<input type="hidden" name="access_token" value={token} />
													<input type="hidden" name="order" value={pagination.order} />
													<input type="hidden" name="orderBy" value={pagination.orderBy} />
													<input type="hidden" name="site" value={localStorage.getItem('site')} />
													<Button type="submit" className="participants-btn-style">Export</Button>
												</form>
												{!role &&
													<Button onClick={this.eventSwitchHandler} className="participants-btn-style">
														Switch Event
												</Button>
												}
											</div>
										</div>
										<PlayersTable {...this.props} pagination={pagination} updatePageNo={this.updatePageNo} />
									</CardContent>
								</Card>
							</div>
						</div> : <ContentLoader />
					}
				</div>
				<Footer />
			</div>
		)
	}
}

const mapStateToProp = (state) => {
	return {
		players: state.players,
		uploadError: state.file.error,
		isUploading: state.file.isUploading,
		uploaded: state.file.uploaded,
	}
};

export default connect(mapStateToProp, {
	getMembers,
	refreshPlayers,
	logout,
	switchEvent,
	uploadFile,
	clearState
})(PlayersList);
