import * as jwt from 'jsonwebtoken';
import {winnerTypes} from '../constants/index'
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {CONSTANTS} from '../constants';

export const WelcomeNote = (url) => {
	if (_.includes(url, CONSTANTS.site_1)) {
		return (`<span style="font-family: "Lucida Grande", "Lucida Sans Unicode", Lucida Sans, Geneva, Verdana, sans-serif"> Please refer to page 16 of your Administrative Handbook for assistance
		with submitting results. If you have any questions or issues, please contact us at
    <span style="color: white; text-decoration: underline;"> <a href="mailto:pitchhitrun@mlb.com">pitchhitrun@mlb.com</a></span>.
		<br/><br/>
		You will receive the sectional details for your winners by May 1st from PHR Headquarters.
		Once you have this information, be sure to inform your winners of the Sectional details.</span>`);
	} else {
		return (`<span style="font-family: "Lucida Grande", "Lucida Sans Unicode", Lucida Sans, Geneva, Verdana, sans-serif"> Please refer to page 5 of your Administrative Handbook for assistance
		with determining champions. If you have any questions or issues, please contact us at
    <span style="color: white; text-decoration: underline;"><a href="mailto:JrHRD@mlb.com">JrHRD@mlb.com</a></span>.
		<br/><br/>
        Upon submission of your results, JrHRD Headquarters will reach out to your winners directly
        to inform them of the details for the Regional Level.</span>`);
	}
};

export const ListingHelpText = (site, programUrlHtml) => {
	// const programId = localStorage.getItem('programId');
	// return (`If you do not see the winner listed here, <a href="https://gcurling.leagueapps.com/tournaments/${programId}-coaches-invitational-classic" target="_blank">click here</a> to register that player`);
	if (_.isEqual(site, CONSTANTS.site_1)) {
		return (`
		<b> ${CONSTANTS.adminListHeading}:</b> <br/>
		To mark a participant as a winner, find the player’s name in the listing below and click on the “trophy” icon.
		If you do not see a particular winner’s name listed below, <a href="${programUrlHtml}" target="_blank">click here</a>
		to add that player. <br /> <br/>
		
		NOTE: If adding multiple winners, be sure to “logout” on the participant registration page between each entry.
		<br/> <br/><b>Creating a Roster:</b><br/>
		Prior to your event, use the “export” button on the right to download a list of online pre-registrants.
		
	<br/> <br/>
	If you are having any issues please contact your PHR rep or email <a href="mailto:pitchhitrun@mlb.com">pitchhitrun@mlb.com</a>
`);
	} else  {
		return (`
	<b> ${CONSTANTS.adminListHeading}:</b> <br/>
		To mark a participant as a winner, find the player’s name in the listing below and click on the “trophy” icon.
		If you do not see a particular winner’s name listed below, <a href="${programUrlHtml}" target="_blank">click here</a>
		to add that player. <br /> <br/>
		
		NOTE: If adding multiple winners, be sure to “logout” on the participant registration page between each entry.
		<br/> <br/><b>Creating a Roster:</b><br/>
		Prior to your event, use the “export” button on the right to download a list of online pre-registrants.
		
	<br/> <br/>
	If you are having any issues please contact your JrHRD rep or email <a href="mailto:jrhrd@mlb.com">jrhrd@mlb.com</a>`);
	}
};

const desc = (a, b, orderBy) => {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

export const stableSort = (array = [], cmp) => {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
};

export const getSorting = (order = '', orderBy = '') => {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

export const styles = theme => ({
	root: {
		width: '100%'
	},
	table: {
		minWidth: 1200
	},
	tableWrapper: {
		overflowX: 'auto',
		padding: 15
	},
});

export const filterRegistrations = (regs = []) => regs.map((item = {}) => {
	item = item || {};
	let phoneNumber = '';
	if (!_.isEmpty(item.parentPhone)) {
		const number = parsePhoneNumberFromString(item.parentPhone);
		phoneNumber = (number && number.formatNational()) || item.parentPhone;
	}
	const winnerTypesMap = {};
	
	_.forEach(winnerTypes, winner => {
		winnerTypesMap[_.camelCase(winner)] = winner;
	});
	
	const selection = _.map(item.winners, winner => winnerTypesMap[winner]);
	
	return {
		playerFirstName: item.firstName || '',
		playerLastName: item.lastName || '',
		parentName: `${item.parentFirstName || ''} ${item.parentLastName || ''}`,
		parentPhone: phoneNumber || '',
		parentEmail: item.parentEmail || '',
		registrationId: item.registrationId || '',
		selection: (selection && selection.length && selection.join(', ')) || '',
		winnerScore: (item.scores && item.scores.length && item.scores.join(', ')) || '',
		programId: item.programId || '',
		userId: item.userId || '',
		programName: item.programName || '',
		division: item.division || '',
		created: (item.created && moment.tz(item.created, "America/New_York").format('L')) || '',
		birthDate: item.birthDate || undefined,
		ageGroup: item.ageGroup || '',
		lastUpdated: item.lastUpdated || ''
	};
});

export const updateWinner = (data, registrationId, winnerType, score) =>
	data.map((item) => {
		if (item.registrationId === registrationId) {
			item.selection = winnerType;
			item.winnerScore = score;
		}
		return item
	});

export const delWinner = (data, registrationId) =>
	data.map((item) => {
		if (item.registrationId === registrationId) {
			item.selection = '';
			item.winnerNotes = '';
			item.winnerScore = '';
		}
		return item
	});

export const isTokenExpired = () => {
	const access_token = localStorage.getItem('access_token');
	if (access_token && jwt.decode(access_token)) {
		const expiry = jwt.decode(access_token).exp;
		const now = Date.now().valueOf() / 1000;
		return now > expiry;
	}
	return false;
};

export const validate = ({
	                         division,
	                         ageGroup,
	                         winnerTypes,
	                         isJrhd
                         }) => {
	const errors = {};
	if (!isJrhd && division.length <= 0) errors.division = 'Division can\'t be blank';
	if (ageGroup.length <= 0) errors.ageGroup = 'Age group can\'t be blank';
	if (_.isEmpty(winnerTypes)) {
		errors.winnerTypes = 'Please select one winner type';
	} else {
		_.forEach(winnerTypes, winner => {
			const keys = Object.keys(winner);
			if (parseInt(winner[keys[0]]) < 0)
				errors[keys[0]] = 'Score field can\'t contain negative values.';
			else if (_.isEmpty(winner[keys[0]]))
				errors[keys[0]] = 'Score field can\'t be blank.';
		});
	}
	
	return errors;
};

export const getSiteName = (registrations) => {
	let siteName;
	for (const item of registrations) {
		if (item.siteName) {
			siteName = item.siteName;
			break;
		}
	}
	return siteName;
};

export const removeLocalStorageItems = () => {
	localStorage.removeItem('access_token');
	localStorage.removeItem('email');
	localStorage.removeItem('firstName');
	localStorage.removeItem('programId');
	localStorage.removeItem('site');
};

export const rem_LS_Items_SwitchButton = () => {
	localStorage.removeItem('access_token');
	localStorage.removeItem('programId');
};



export const createTableColumns = ({email, programId,siteId, date: {from = undefined} = {}}) => {
	const columns =  [];
	columns.push({id: 'site', numeric: false, disablePadding: false, label: 'SiteId', sortable: true});
	columns.push({id: 'firstName', numeric: false, disablePadding: false, label: 'First Name', sortable: true});
	columns.push({id: 'email', numeric: false, disablePadding: false, label: 'Email', sortable: true});
	columns.push({id: 'programId', numeric: false, disablePadding: false, label: 'Program Id', sortable: true});
	columns.push({id: 'createdAt', numeric: false, disablePadding: false, label: 'Created At', sortable: true});
	columns.push({id: 'actions', numeric: false, disablePadding: false, label: 'Actions', action: true, sortable: false});
	return columns
};

export const checkError = (id = '', fields = {}, error) => {
	const reg = new RegExp('^[^@]+@[^@]+\\.[^@]+$');
	const {firstName, email, programId, site} = fields || {};
	if ((error && _.isEqual(id, 'email') && _.isEmpty(email))) {
		return true;
	} else if ((error && _.isEqual(id, 'firstName') && _.isEmpty(firstName))) {
		return true;
	} else if (_.isEqual(id, 'email') && !_.isEmpty(email) && !reg.test(email)) {
		return true
	} else if (error && _.isEqual(id, 'programId') && _.isEmpty(programId)) {
		return true
	} else if (error && _.isEqual(id, 'site') && _.isEmpty(site)){
		return true;
	}
	return false;
};

export const isLocalStorageItemExists = (key) => {
	const item = localStorage.getItem(key);
	return !_.isEmpty(item);
	
};
