import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {EDIT_ADMIN_FIELDS} from './../../../constants';
import {EDIT_ADMIN} from "../../../reducers/admin";
import {isEmpty} from 'lodash';
import {checkError} from "../../../helpers/utils";

class EditModal extends Component {
	reg = new RegExp('^[^@]+@[^@]+\\.[^@]+$');
	
	constructor(props) {
		super(props);
		const {selectedAdmin = {}} = this.props;
		const {site, firstName, email, programId, ...rest} = selectedAdmin || {};
		this.state = {
			selectedAdmin: {
				site: site || '',
				firstName: firstName || '',
				email: email || '',
				programId: programId || '',
			},
			rest,
			error: false,
		}
	}
	
	onUpdateState = (event = {}, id = '') => {
		let {selectedAdmin = {}} = this.state;
		const {target: {value = ''} = {}} = event || {};
		selectedAdmin[id] = value;
		this.setState({selectedAdmin})
	};
	
	onHandleUpdate = () => {
		const {selectedAdmin, rest} = this.state;
		const {editAdmin} = this.props;
		const {firstName, email, programId, site} = selectedAdmin || {};
		if (isEmpty(site) || isEmpty(firstName) || isEmpty(email) || isEmpty(programId) || !this.reg.test(email)) {
			this.setState({error: true});
			return null;
		}
		
		editAdmin({firstName, email, site, programId, ...rest});
		this.setState({error: false});
	};
	
	
	_renderEditableFields = () => {
		const {selectedAdmin = {}, error} = this.state;
		
		return EDIT_ADMIN_FIELDS.map((item = {}, index) => {
			const {autoFocus = false, id = '', label = '', type = ''} = item || {};
			return (
				<TextField
					key={index}
					autoFocus={autoFocus}
					value={selectedAdmin[id]}
					error={checkError(id, selectedAdmin, error)}
					margin="dense"
					variant="outlined"
					id={id}
					label={label}
					type={type}
					onChange={(event) => this.onUpdateState(event, id)}
					fullWidth
				/>
			)
		})
		
	};
	
	render() {
		const {isOpen = false, handleClose, success, message, loading} = this.props;
		return (
			<Dialog
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Edit Admin</DialogTitle>
				<DialogContent>
					<DialogContentText>
					
					</DialogContentText>
					{this._renderEditableFields()}
					{!(success && loading) && <span className='mt-3' style={{color: 'red'}}>{message}</span>}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={this.onHandleUpdate} color="primary">
						Update
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}


const mapDispatchToProps = (dispatch) => (
	{
		editAdmin: (data = {}) => dispatch(EDIT_ADMIN(data)),
	}
);

export default connect(null, mapDispatchToProps)(EditModal);
