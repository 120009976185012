import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableHeader from './TableHeader';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {PAGINATION} from "../../../constants";
import {styles} from "../admin.styles";
import EditModal from "../EditAdminModal/index";
import DeleteModal from "../DeleteAdminModal/index";
import {CLEAR_EDIT_ADMIT_STATE, DELETE_ADMIN, GET_ADMINS} from "../../../reducers/admin";
import {createTableColumns, isLocalStorageItemExists} from "../../../helpers/utils";
import * as moment from "moment-timezone";
import './index.css';
import {checkLocalStorage} from "../../../sagas/user";

class AdminTable extends Component {
	constructor(props) {
		super(props);
		const columns = createTableColumns(props);
		
		this.state = {
			columns,
			pagination: PAGINATION,
			isEditOpen: false,
			isDeleteOpen: false,
			selectedAdmin: {},
		}
	}
	
	
	componentWillReceiveProps(newProps) {
		const columns = createTableColumns(newProps);
		this.setState({pagination: newProps.pagination, columns});
		const {success, loading, dupData} = newProps;
		const {dupData: prevDupData} = this.props;
		if (success && !loading) {
			this.onHandleEditModalCloseUpdate(false)
		}
		
		if(dupData !== prevDupData){
			this.setState({selectedAdmin: dupData})
		}
	}
	
	onHandlePageChange = (event, page) => {
		const {getAdmins, email, programId, date, siteId} = this.props;
		let {pagination = {}} = this.state;
		pagination.page = page;
		getAdmins({email, programId, date, siteId, ...pagination});
		this.setState({pagination});
		this.props.handlePagination(pagination);
	};
	
	onHandleRowsPerPage = (event) => {
		const {target: {value = ''} = {}} = event || {};
		let {pagination = {}} = this.state;
		const {getAdmins, email, programId, date, siteId} = this.props;
		pagination.pageSize = value;
		getAdmins({email, programId, date, siteId, ...pagination});
		this.setState({pagination});
		this.props.handlePagination(pagination);
	};
	
	onHandleEditModalOpen = (selectedAdmin = {}) => {
		const {history} = this.props;
		checkLocalStorage();
		const isToken = isLocalStorageItemExists('access_token');
		if(!isToken){
			history.push('/');
			return null;
		}
		this.setState({isEditOpen: true, selectedAdmin});
	};
	
	onHandleEditModalClose = () => {
		const {onClearErrorState} = this.props;
		this.setState({isEditOpen: false});
		onClearErrorState()
	};
	
	onHandleEditModalCloseUpdate = (isEditOpen = false) => {
		this.setState({isEditOpen})
	};
	
	onHandleDeleteModalOpen = (selectedAdmin = {}) => {
		const {history} = this.props;
		checkLocalStorage();
		const isToken = isLocalStorageItemExists('access_token');
		if(!isToken){
			history.push('/');
			return null;
		}
		
		this.setState({isDeleteOpen: true, selectedAdmin});
	};
	
	onHandleDeleteModalClose = () => {
		this.setState({isDeleteOpen: false});
	};
	
	onHandleDelete = () => {
		const {selectedAdmin = {}} = this.state;
		const {id = ''} = selectedAdmin || {};
		const {deleteAdmin} = this.props;
		deleteAdmin({id});
		this.setState({isDeleteOpen: false})
	};
	
	onHandleSortRequest = (event, orderBy) => {
		const {pagination = {}} = this.state;
		const {orderBy: _orderBY, order: _order} = pagination || {};
		let order = "desc";
		
		if (_orderBY === orderBy && _order === "desc") {
			order = "asc";
		}
		
		this.setState({pagination: {...pagination, order, orderBy}}, () => {
			const {pagination = {}} = this.state;
			const {email, programId, date, siteId, getAdmins} = this.props;
			getAdmins({email, programId, date, siteId, ...pagination});
			this.props.handlePagination(this.state.pagination);
		});
	};
	
	_renderEditModal = () => {
		const {isEditOpen, selectedAdmin} = this.state;
		if (!isEditOpen) {
			return null;
		}
		
		return (
			<EditModal
				isOpen={isEditOpen}
				handleClose={this.onHandleEditModalClose}
				handleCloseUpdate={this.onHandleEditModalCloseUpdate}
				selectedAdmin={selectedAdmin}
				{...this.props}
			/>
		)
	};
	
	_renderDeleteModal = () => {
		const {isDeleteOpen} = this.state;
		return (
			<DeleteModal
				isOpen={isDeleteOpen}
				handleClose={this.onHandleDeleteModalClose}
				handleDelete={this.onHandleDelete}
			/>
		)
	};
	
	_renderTableActions = (item = {}) => {
		return (
			<div key={0} id='admin-action-btn' className='d-flex align-items-center justify-content-center flex-wrap'>
				<button className='btn mr-1' onClick={() => this.onHandleEditModalOpen(item)}>
					Edit
				</button>
				<button className="btn" onClick={() => this.onHandleDeleteModalOpen(item)}>
					Delete
				</button>
			</div>
		)
	};
	
	_renderTableBody = () => {
		const {classes = {}, users = {}} = this.props;
		const {data = []} = users || {};
		return data.map((item = {}, index) => {
			const {site = '', firstName = '', email = '', programId = '', createdAt = ''} = item || {};
			return (
				<TableRow key={index} className={classes.row}>
					<TableCell> {site} </TableCell>
					<TableCell> {firstName} </TableCell>
					<TableCell> {email} </TableCell>
					<TableCell> {programId} </TableCell>
					<TableCell> {createdAt && moment.tz(createdAt, "America/New_York").format('MM/DD/YY HH:mm:ss')} </TableCell>
					<TableCell> {this._renderTableActions(item)} </TableCell>
				</TableRow>
			)
		})
	};
	
	_renderTable = () => {
		const {classes = {}, users = {}, loading = false} = this.props;
		const {pagination = {}} = this.state;
		const {page, pageSize, order, orderBy} = pagination || {};
		const {size = 0, data = []} = users || {};
		
		if (loading) {
			return (
				<div style={{height: "500px"}} className='d-flex justify-content-center align-items-center'>
					<CircularProgress className={classes.progress}/>
				</div>
			)
		}
		
		if (data && !data.length) {
			return (
				<div className='d-flex justify-content-center align-items-center'>
					<span> No data found!</span>
				</div>
			)
		}
		return (
			<Paper className={classes.root}>
				<Table className={classes.table}>
					<TableHeader
						order={order}
						orderBy={orderBy}
						onRequestSort={this.onHandleSortRequest}
						columns={this.state.columns}
					/>
					<TableBody>
						{this._renderTableBody()}
					</TableBody>
				</Table>
				{this._renderEditModal()}
				{this._renderDeleteModal()}
				<div>
					<TablePagination
						rowsPerPageOptions={[100, 200, 500, 1000]}
						component="div"
						count={size}
						rowsPerPage={pageSize}
						page={page}
						backIconButtonProps={{
							"aria-label": "Previous Page"
						}}
						nextIconButtonProps={{
							"aria-label": "Next Page"
						}}
						onChangePage={this.onHandlePageChange}
						onChangeRowsPerPage={this.onHandleRowsPerPage}
					/>
				</div>
			</Paper>
		)
	};
	
	render() {
		return (
			<Fragment>
				{this._renderTable()}
			</Fragment>
		);
	}
}

AdminTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (
	{
		admins: {users = {}, loading = false, message, success = true, dupData = {}} = {}
	}
) => (
	{users, loading, message, success, dupData}
);

const mapDispatchToProps = (dispatch) => (
	{
		getAdmins: (data = {}) => dispatch(GET_ADMINS(data)),
		deleteAdmin: (data = {}) => dispatch(DELETE_ADMIN(data)),
		onClearErrorState: () => dispatch(CLEAR_EDIT_ADMIT_STATE()),
	}
);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AdminTable));
